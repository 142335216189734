import { Fragment } from 'react';
import { Expandable } from 'UI';

const MyPagesAnswers = () => {
	
	return (
		<Fragment>
			<Expandable trigger="Jag vill inte längre få ert nyhetsbrev">
				Om du inte längre vill ha vårt nyhetsbrev kan du via nyhetsbreven avregistrera dig från våra utskick. Du kan även maila dataskydd@strollysweden.com så löser vi det.
			</Expandable>

			<Expandable trigger="Jag vill radera mitt konto">
				Eftersom att vi på Strolly har vissa skyldigheter när det kommer till personuppgifter och GDPR önskar vi att du mailar ditt ärende till dataskydd@strollysweden.com.
			</Expandable>

			<Expandable trigger="Kan jag reservera produkter på hemsidan?">
				Du kan alltid välja att få din vagn levererad en månad framåt i tiden utan extra kostnad.
			</Expandable>

			<Expandable trigger="Var kan jag se min orderhistorik?">
				Under Mina Sidor hittar du dina tidigare ordrar.
			</Expandable>

			<Expandable trigger="Jag vill veta mer om hur ni behandlar mina personuppgifter">
				Om du önskar att få reda på hur vi behandlar dina personuppgifter hittar du all vår information om detta på www.strollysweden.com/integritetspolicy.
			</Expandable>
		</Fragment>
	);

};

MyPagesAnswers.position = 'mypages';

export default MyPagesAnswers;