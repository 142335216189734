import jwt_decode from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	token: null,
};

export const authenticationSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		checkTokenValidity: (state) => {
			if (state.token != null) {
				const token = jwt_decode(state.token);
				if (token.exp * 1000 < new Date().getTime()) {
					// Token invalid
					state.token = initialState.token;
				}
			}
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
		resetAuthenticationState: (state) => {
			state.token = initialState.token;
		},
	},
});

export const {
	checkTokenValidity,
	setToken,
	resetAuthenticationState,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;