
export const required = (value) => {
	if (value == null || value == '') {
		return 'Detta fält är obligatoriskt';
	}

	return undefined;
};

export const requiredSilent = (value) => {
	if (value == null || value == '') {
		return ' ';
	}

	return undefined;
}

export const personalNumber = (value) => {
	if (value == null || value == '') {
		return 'Detta fält är obligatoriskt';
	}

	const regex = /^(18\d{2}|19\d{2}|20\d{2}|\d{2})(0[1-9]|1[0-2])([0][1-9]|[1-2][0-9]|3[0-1])(\-|\+)?([\d]{4})$/g;
	const found = value.match(regex);
	if (found && found.length > 0) {
		return undefined;
	}

	return 'Ange giltigt personnummer';
};

export const email = (value) => {
	if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
		return undefined;
	}

	return 'Ange en giltig mejladress';
};

export const phone = (value) => {
	if (parseInt(value, 10) != value || value.length < 10) {
		return 'Ange ett giltigt telefonnummer';
	}

	return undefined;
};