/**
 * @typedef {import('@reduxjs/toolkit/dist/query/react/buildHooks').UseQuery} Query
 * @typedef {import('@reduxjs/toolkit/dist/query/react/buildHooks').UseQueryStateBaseResult} QueryResult
 */

import { useState, useEffect, useRef } from 'react';

/**
 * @param {Query} query
 * @param {object} queryParams 
 * @param {number} initialPage Default: 1
 * @param {number} offset In px. Default: 300px
 * @returns QueryResult
 */
export const usePaginatedQuery = (query, queryParams = {}, initialPage = 1, offset = 300) => {
	const end = useRef(false);
	const addedPage = useRef(0);
	const [activeParams, setActiveParams] = useState(queryParams);
	const [pages, setPages] = useState(undefined);
	const [currentPage, setCurrentPage] = useState(initialPage);
	const results = query({
		page: currentPage,
		...activeParams
	});

	const restorePage = () => {
		setCurrentPage(1);
		setPages(undefined);
		addedPage.current = 0;
		end.current = 0;
	};

	useEffect(() => {
		if (JSON.stringify(activeParams) !== JSON.stringify(queryParams)) {
			setActiveParams(queryParams);
			restorePage();
		}
	}, [queryParams]);

	useEffect(() => {
		const handleScroll = () => {
			const { offsetHeight, scrollHeight, scrollTop } = document.scrollingElement;

			if ((scrollHeight - offsetHeight) - scrollTop < offset && !results.isFetching) {
				if (!end.current) {
					setCurrentPage(currentPage + 1);
				}
			}
		};

		document.addEventListener('scroll', handleScroll);

		return () => {
			document.removeEventListener('scroll', handleScroll);
		};
	}, [results]);

	useEffect(() => {
		if (!results.isSuccess) {
			return () => true;
		}

		if (results.data == null || results.data.length == 0) {
			end.current = true;
			setPages(pages ?? []);
			return () => true;
		}

		if (results.originalArgs.page > addedPage.current) {
			setPages([...(pages ?? []), ...results.data]);

			addedPage.current = results.originalArgs.page;
		}
	}, [results.data]);

	return {
		...results,
		data: pages,
	};
};
