import { Skeleton} from "UI";
import AccessoriesLoader from "./AccessoriesLoader";

const SingleLoader = ({ registerPosition }) => {

	return (
		<div className="flex flex-wrap mt-spacing">

			<div className="w-full md:w-1/2 md:pr-10">
				<div className="block md:hidden">
					<Skeleton className="pt-[143%]" />
				</div>

				<div className="hidden md:block">
					<Skeleton className="pt-[70%]" />
				</div>

				<div className="block md:hidden mt-8">
					<Skeleton size="text-4xl" className="w-3/4" />

					<Skeleton className="mt-2 w-1/4" />

					<div className="my-8 border-b border-gray-300" />
				</div>

				<div className="hidden md:flex gap-3 mt-3 flex-wrap">
					<Skeleton className="h-16 w-16" />
					<Skeleton className="h-16 w-16" />
					<Skeleton className="h-16 w-16" />
				</div>

				<div className="my-12">
					<Skeleton size="text-xl" />
					<Skeleton size="text-xl" className="mt-2 w-10/12" />
					<Skeleton size="text-xl" className="mt-2 w-11/12" />
					<Skeleton size="text-xl" className="mt-2" />
				</div>

				{registerPosition('after_description')}
			</div>

			<div className="w-full md:w-1/2 md:pl-10 mt-10 md:mt-0">
				<div className="hidden md:block">
					<Skeleton size="text-4xl" className="w-3/4" />

					<Skeleton className="mt-2 w-1/4" />

					<div className="my-8 border-b border-gray-300" />
				</div>

				<AccessoriesLoader />
			</div>

		</div>
	);

};

export default SingleLoader;