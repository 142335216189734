import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getApiUrl, prepareHeaders } from 'lib/env';
import { clearCartDiscount, setCartDiscount } from './cart';

export const subscriptionApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: getApiUrl() + 'api/v1/',
		prepareHeaders: prepareHeaders,
	}),
	reducerPath: 'subscriptions',
	tagTypes: ['Subscriptions'],
	endpoints: (build) => ({
		getMySubscriptions: build.query({
			query: () => ({
				url: `subscription`,
			}),
			providesTags: ['Subscriptions'],
		}),
		getSubscription: build.query({
			query: (id) => ({
				url: `subscriptions/${id}`,
			}),
			providesTags: (result, error, id) => [{ type: 'Subscriptions', id}],
		}),
		createSubscription: build.mutation({
			query: (subscription) => ({
				url: `${getApiUrl()}api/v2/subscription/bookpayment`,
				method: 'POST',
				body: subscription,
			}),
			invalidatesTags: ['Subscriptions'],
		}),
		createStrollySubscription: build.mutation({
			query: (subscription) => ({
				url: `subscription`,
				method: 'POST',
				body: subscription,
			}),
			invalidatesTags: ['Subscriptions'],
		}),
		getPaymentVerification: build.query({
			query: (reference) => ({
				url: `subscription/payment/verification/${reference}`,
			}),
		}),
		getPaymentInformation: build.query({
			query: (subscription) => ({
				url: `subscription/payment/methods/${subscription}`,
			}),
		}),
		getSubscriptionSigningStatus: build.query({
			query: (reference) => ({
				url: `subscription/signing/status/${reference}`
			}),
		}),
		validateCampaignCode: build.mutation({
			query: (values) => ({
				url: `subscription/validate/campaign/${values.code}/stroller/${values.productId}`,
				method: 'GET',
			}),
			onQueryStarted: async (values, { dispatch, queryFulfilled }) => {
				const { data } = await queryFulfilled;

				if (data != null) {
					dispatch(setCartDiscount(data));
				} else {
					dispatch(clearCartDiscount());
				}
			},
		}),
	}),
});

export const {
	useGetMySubscriptionsQuery,
	useGetSubscriptionQuery,
	useCreateSubscriptionMutation,
	useCreateStrollySubscriptionMutation,
	useGetPaymentInformationQuery,
	useGetSubscriptionSigningStatusQuery,
	useGetPaymentVerificationQuery,
	useValidateCampaignCodeMutation,
} = subscriptionApi;