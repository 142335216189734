import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'UI';

const Stars = () => {

	return (
		<div className="w-full md:w-3/4 xl:w-2/3 md:pr-10 mt-10 mx-auto">

			<div className="text-center">
				<h3 className="text-2xl">Hitta din drömvagn idag!</h3>

				<p className="text-gray-700 text-md mt-2">
					Du kanske har sett att våra vagnar är märkta med 1, 2 eller 3 stjärnor? 
					Stjärnmodellen visar dig om vagnen är en helt ny vagn eller om den har rullat innan, stjärnorna motsvarar också olika prisklasser. 
				</p>
			</div>

			<div className="flex justify-between mt-10 flex-wrap">
				<div className="w-1/3 pr-2">
					<strong className="block font-bold mb-1 text-green">
						<Icon name="Star" className="!h-5" />
						<Icon name="Star" className="!h-5" />
						<Icon name="Star" className="!h-5" />
					</strong>
					
					<p className="text-gray-700 text-sm">
						En vagn som är helt ny eller i nyskick.
					</p>
				</div>

				<div className="w-1/3 pl-2 pr-2">
					<strong className="block font-bold mb-1 text-green">
						<Icon name="Star" className="!h-5" />
						<Icon name="Star" className="!h-5" />
						<Icon name="Star_Outline" className="!h-5" />
					</strong>

					<p className="text-gray-700 text-sm">
						Innebär att vagnen har streamats innan och rullat med en eller flera andra familjer men är i mycket gott skick!
					</p>
				</div>

				<div className="w-1/3 pl-2">
					<strong className="block font-bold mb-1 text-green">
						<Icon name="Star" className="!h-5" />
						<Icon name="Star_Outline" className="!h-5" />
						<Icon name="Star_Outline" className="!h-5" />
					</strong>

					<p className="text-gray-700 text-sm">
						Innebär att vagnen har streamats innan och rullat med en eller flera andra familjer men är i brukligt skick!
					</p>
				</div>
			</div>

			<div className="mt-12 mb-4 text-center">
				Fler funderingar?

				<Link to="/faq" className="inline-flex items-center font-semibold text-blue ml-2">
					Se våra vanliga frågor
					<Icon className="ml-1 -rotate-90 !h-3" name="Chevron" />
				</Link>
			</div>

		</div>
	);

};

Stars.position = 'content';

export default Stars;