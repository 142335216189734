import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { persistReducer } from 'redux-persist';
import { persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

import authenticationReducer from 'services/authentication';
import cartReducer from 'services/cart';
import filtersReducer from 'services/filters';
import cookiesReducer from 'services/cookies';
import { strollersApi } from 'services/strollers';
import { subscriptionApi } from 'services/subscriptions';
import { userApi } from 'services/user';
import { brandsApi } from 'services/brands';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['authentication', 'cart', 'cookies']
};

// Load reducers from services (or other)
const reducers = combineReducers({
	authentication: authenticationReducer,
	cart: cartReducer,
	cookies: cookiesReducer,
	filters: filtersReducer,
	[strollersApi.reducerPath]: strollersApi.reducer,
	[subscriptionApi.reducerPath]: subscriptionApi.reducer,
	[userApi.reducerPath]: userApi.reducer,
	[brandsApi.reducerPath]: brandsApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	}).concat(
		strollersApi.middleware,
		subscriptionApi.middleware,
		userApi.middleware,
		brandsApi.middleware,
	),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
