import { useClearFilter, useSetFilter } from 'hooks/filters';
import { Expandable, Radio } from 'UI';
import { formatPrice } from 'lib/numbers';

const ListPriceFilter = ({ filterOptions, deviceType, pageName }) => {
	const setFilter = useSetFilter(pageName);
	const clearFilter = useClearFilter(pageName);

	const onChange = (interval) => {
		setFilter('minPrice', interval.minPrice);
		setFilter('maxPrice', interval.maxPrice);
	};

	const onClear = () => {
		clearFilter('minPrice');
		clearFilter('maxPrice');
	};

	return (
		<Expandable trigger="Pris" open={deviceType == 'unknown'}>
			<div className="pt-6">
				<div>
					<Radio
						name="price"
						value="all"
						label="Alla"
						className="mb-2"
						onChange={onClear}
					/>
				</div>

				{filterOptions != null ?
					filterOptions.priceIntervals.map((interval, index) => {
						return <div key={index}>
							<Radio
								name="price"
								value={`${interval.minPrice}-${interval.maxPrice}`}
								label={`${interval.minPrice} - ${formatPrice(interval.maxPrice)} / mån`}
								className="mb-2"
								onChange={() => onChange(interval)}
							/>
						</div>
					})
					: null}
			</div>
		</Expandable>
	);

};

ListPriceFilter.position = 'filters';

export default ListPriceFilter;