import { useParams } from 'react-router-dom';
import { usePage } from 'hooks/page';
import { getIdFromSlug } from 'lib/url';
import { usePaginatedQuery } from 'hooks/pagination';
import { useGetPageFilters } from 'hooks/filters';
import { useGetFilterOptionsQuery, useGetStrollersQuery } from 'services/strollers';
import { useGetBrandQuery } from 'services/brands';

import { Grid } from 'UI';

import StrollerCard from 'components/StrollerCard';
import StrollerCardPlaceholder from 'components/StrollerCard/Placeholder';

import BrandHero from './_modules/BrandHero';
import MoreProducts from './_modules/MoreProducts';
import FilterLink from './_modules/FilterLink';
import ListPriceFilter from 'containers/Pages/Strollers/_modules/ListPriceFilter';
import ListStarFilter from 'containers/Pages/Strollers/_modules/ListStarFilter';
import ListColorFilter from 'containers/Pages/Strollers/_modules/ListColorFilter';

const ListStrollers = () => {
	const { slug } = useParams();
	const activeFilters = useGetPageFilters('brand');

	const { data: filterOptions } = useGetFilterOptionsQuery();
	const { data: brand } = useGetBrandQuery(getIdFromSlug(slug));
	const { data: strollers, isFetching } = usePaginatedQuery(useGetStrollersQuery, {
		brandIds: getIdFromSlug(slug),
		...activeFilters
	});

	const [registerPosition, SEO] = usePage('brand', {
		filterOptions,
		brand,
	}, [
		BrandHero,
		MoreProducts,
		ListPriceFilter,
		ListStarFilter,
		ListColorFilter,
		FilterLink,
	]);

	return (
		<div className="mt-spacing">
			<SEO>
				{brand != null ?
					<title>Barnvagnar från {brand.name}</title>
				: null}
			</SEO>

			{registerPosition('before_list')}

			<div className="flex gap-x-24 flex-col md:flex-row">
				<div className="md:basis-1/4">
					<div className="mt-10 mb-12 md:mb-0 pb-10 md:pb-0 border-gray-300 border-b md:border-b-0">
						<h3 className="text-2xl font-medium">Filtrering</h3>

						{registerPosition('filters')}
					</div>
				</div>

				<div className="md:basis-3/4">
					{strollers != null && strollers.length == 0 && !isFetching ?
						<div className="text-2xl font-semibold text-center">
							Inga barnvagnar hittades
						</div>
					: null}

					<Grid>
						{strollers != null ?
							strollers.map((stroller) => {
								return (
									<StrollerCard
										key={stroller.strollerId}
										stroller={stroller}
									/>
								);
							})
						: null}

						{isFetching ?
							<>
								<StrollerCardPlaceholder />
								<StrollerCardPlaceholder />
								<StrollerCardPlaceholder />
							</>
						: null}
					</Grid>
				</div>
			</div>

			{registerPosition('after_list')}
		</div>
	);

};

export default ListStrollers;