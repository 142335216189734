import cx from 'classnames';
import Icon from 'UI/Icon';

const Select = ({ className, ...props }) => {

	return (
		<div className="relative">
			<select
				className={cx(
					'appearance-none',
					'transition-colors',
					'duration-300',
					'border',
					'border-gray-700/40',
					'py-4 px-5',
					'rounded-md',
					'block',
					'w-full',
					'outline-none',
					'focus:border-gray-700',
					'bg-white',
				)}
				{...props}
			/>

			<span className="h-full flex items-center absolute right-4 top-0">
				<Icon name="Chevron" className="!h-3 transition-all group-focus:rotate-180" />
			</span>
		</div>
	);

};

export default Select;