import { useEffect} from 'react';
import { Helmet } from 'react-helmet';

/**
 * Changes document head. Takes plain HTML and outputs plain HTML.
 */
export const SEO = ({ children }) => {
	const defaultTitle = "Barnvagn på abonnemang | Strolly";

	useEffect(() => {
		return () => {
			// unmount
			document.title = defaultTitle;
		};
	}, []);

	return (
		<Helmet
			titleTemplate="%s | Strolly"
			defaultTitle={defaultTitle}
			children={children}
		/>
	);
};

/**
 * @param {string} name Page name
 * @param {object} context Data sent to modules
 * @param {Array} modules
 * @returns {Array} registerPosition, SEO
 */
export const usePage = (name, context = {}, modules = []) => {
	if (context == null) {
		context = {};
	}

	// Validate modules
	// Require position
	modules.forEach((module) => {
		if (module.position == null) {
			throw new TypeError(`Module requires property "position" to be set on ${module.name} for page ${name}`);
		}
	});

	context.pageName = name;

	const registerPosition = (position) => {
		const positionParts = modules.filter((module) => module.position === position);
		return positionParts.map((Part, index) =>
			<Part key={position + '-' + index} {...context} />
		);
	};

	return [registerPosition, SEO];
}

/**
 * @param {string} position Position name
 * @returns {import('react').JSXElementConstructor} Component
 */
// TODO: Can we use this instead of Component.position property
export const usePagePosition = (position) => {
	console.log('use page position', position, this)
	const Position = ({ children, ...props }) => (
		<div {...props}>{children}</div>
	);

	Position.position = position;

	return Position;
}
