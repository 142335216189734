import { Fragment } from 'react';
import { Skeleton } from 'UI';

const AccessoriesLoader = () => {
	return (
		<Fragment>
			<h2 className="text-2xl">Välj prenumerationsperiod</h2>

			<div className="grid xl:grid-cols-3 gap-4 mt-4">
				<Skeleton className="py-12" />
				<Skeleton className="py-12" />
				<Skeleton className="py-12" />
			</div>

			<h2 className="text-2xl mt-14 font-medium">Välj tillbehör</h2>

			<div className="mt-4">
				<Skeleton className="py-14 mb-3" />
				<Skeleton className="py-14 mb-3" />
			</div>
		</Fragment>
	);

};

export default AccessoriesLoader;