import { Expandable, Tag } from 'UI';
import { useIsFilterActive, useToggleFilter } from 'hooks/filters';

const ListColorFilter = ({ filterOptions, deviceType, pageName }) => {
	const isActive = useIsFilterActive(pageName);
	const toggleFilter = useToggleFilter(pageName);

	return (
		<Expandable trigger="Färg" open={deviceType == 'unknown'}>
			<div className="flex flex-wrap pt-6">
				{filterOptions != null ?
					filterOptions.colors.map((color) => (
						<Tag
							key={color.colorId}
							active={isActive('colorIds', color.colorId)}
							onClick={() => toggleFilter('colorIds', color.colorId)}
						>
							<div className="flex items-center flex-col px-2">
								<div
									className="rounded-full w-8 h-8"
									style={{ background: color.hex }}
								/>

								<div className="mt-1">
									{color.name}
								</div>
							</div>
						</Tag>
					))
					: null}
			</div>
		</Expandable>
	);

};

ListColorFilter.position = 'filters';

export default ListColorFilter;