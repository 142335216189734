import { subtractPercentage } from 'lib/numbers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useGetCartItems = () => {
	const items = useSelector((state) => state.cart.items);
	const discount = useSelector((state) => state.cart.discount);

	if (items == null) {
		return [];
	};

	if (discount != null && discount > 0) {
		// Apply discount on products
		return items.map((item) => ({
			...item,
			products: item.products.map((product) => ({
				...product,
				regularPrice: product.price,
				price: subtractPercentage(product.price, discount),
			})),
		}));
	}

	return items;
};

export const useGetCartPaymentMethod = () => {
	const items = useGetCartItems();

	return useMemo(() => {
		const foundResurs = items.find((cartItem) => {
			if (cartItem == null || cartItem.products == null) {
				return false;
			}

			return cartItem.products.find((product) =>
				product.reference == 'STROLLER' && product.paymentMethod == 'RESURS'
			);
		});

		if (foundResurs != null) {
			return 'RESURS';
		}

		return 'STROLLY';
	}, [items]);
};

export const useGetCartDeliveries = () => {
	const items = useGetCartItems();

	return useMemo(() => {
		let result = [];

		items.forEach((cartItem) => {
			if (cartItem == null || cartItem.products == null) {
				return false;
			}

			const stroller = cartItem.products.find((product) =>
				product.reference == 'STROLLER'
			);

			result = stroller.deliveries;
		});

		return result;
	}, [items]);
};

export const useGetCartTotals = () => {
	const items = useGetCartItems();

	return useMemo(() => {
		let sum = 0;

		items.forEach((cartItem) => {
			if (cartItem == null || cartItem.products == null) {
				return false;
			}

			cartItem.products.forEach((product) => {
				if (product?.price != null) {
					sum += parseInt(product.price, 10);
				}
			});
		});

		return sum;
	}, [items]);
};

export const useGetCartSubscriptionPeriod = () => {
	const items = useGetCartItems();

	return useMemo(() => {
		let period = 0;

		items.forEach((cartItem) => {
			if (cartItem == null || cartItem.products == null) {
				return false;
			}

			const stroller = cartItem.products.find((product) =>
				product.reference == 'STROLLER'
			);

			if (stroller != null) {
				period = stroller.subscriptionPeriod;
			}
		});

		return period;
	}, [items]);
};