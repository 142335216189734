import { useSelector, useDispatch } from 'react-redux';
import { toggleFilter, setFilter, clearFilter } from 'services/filters';

/**
 * @param {string} page
 */
export const useIsFilterActive = (page) => {
	const activeFilters = useSelector((state) => state.filters.active);

	/**
	 * @param {string} name
	 * @param {string} filter
	 * @returns {bool} bool
	 */
	return (name, filter) => {
		return activeFilters.indexOf(page + '~' + name + '~' + filter) > -1;
	};
};

/**
 * @param {string} page
 */
export const useToggleFilter = (page) => {
	const dispatch = useDispatch();

	/**
	 * @param {string} name
	 * @param {string} filter
	 */
	return (name, filter) => {
		dispatch(toggleFilter({
			page,
			filter,
			name,
		}));
	};
};

export const useSetFilter = (page) => {
	const dispatch = useDispatch();

	/**
	 * @param {string} name
	 * @param {string} filter
	 */
	return (name, filter) => {
		dispatch(setFilter({
			page,
			filter,
			name,
		}));
	};
};

/**
 * @param {string} page 
 */
export const useClearFilter = (page) => {
	const dispatch = useDispatch();

	/**
	 * @param {string} name
	 * @param {string} filter
	 */
	return (name) => {
		dispatch(clearFilter({
			page,
			name,
		}));
	};
};

/**
 * @param {string} page 
 * @returns {object}
 */
export const useGetPageFilters = (page) => {
	const activeFilters = useSelector((state) => state.filters.active);
	let filters = {};

	activeFilters.forEach((filter) => {
		if (filter.indexOf(page + '~') == -1) {
			return false;
		}

		const parsed = filter.replace(page + '~', '');
		const key = parsed.split('~')[0];
		const value = parsed.split('~')[1];

		if (filters[key] == null) {
			filters[key] = value;
		} else {
			filters[key] += ',' + value;
		}
	});

	return filters;
};