import { usePage } from "hooks/page";
import { getPublicURL } from "lib/url";

const PrivacyPolicy = () => {
	const [registerPosition, SEO] = usePage('privacyPolicy');

	return (
		<div className="mt-spacing">
			<SEO>
				<title>Integritetspolicy</title>
			</SEO>

			<h1 className="text-4xl font-semibold">Integritetspolicy</h1>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Om GDPR
				</h3>

				<p className="mt-4">
					<img
						className="float-right ml-6 mb-6 h-72"
						src={`${getPublicURL()}assets/3ed0cff.png`}
						alt="Man pointing at list on paper."
					/>

					Dataskyddsförordningen (GDPR) är en ny EU-förordning om dataskydd och integritet. När den börjar gälla den 25:e maj 2018 ersätter den i stor utsträckning det nuvarande dataskyddsregelverket, vilket innebär att detta är den största förändringen av europeiska dataskyddsregler de senaste 20 åren.
					<br /><br />
					Enkelt förklarat gäller GDPR för alla personuppgifter som hanteras inom EU:s gränser, eller avser personer i EU – oavsett var den organisation som hanterar uppgifterna finns.
					<br /><br />
					Det huvudsakliga syftet med GDPR är att förena och förenkla lagstiftningen samt stärka dataskyddet för den enskilde individen på samma sätt i hela EU.
					<br /><br />
					För individer innebär det här en ökad kontroll över ens personuppgifter, och för företag som är verksamma inom EU medför GDPR vissa ytterligare krav kring hur personuppgifter hanteras. Men även om vissa saker förändras, är det mycket som förblir detsamma.
					<br /><br />
					Strolly hanterar personuppgifter med största omsorg, och vid behov anpassar vi våra tjänster, system och processer i enlighet med de krav som anges i GDPR.
				</p>
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					GDPR för Strollys kunder
				</h3>

				<p className="mt-4">
					Hos Strolly värnar vi om våra kunder, vilket innebär att vi bryr oss om dig och din integritet.
					<br /><br />
					För oss är det viktigt att villkoren för våra tjänster är lättillgängliga samt att du är medveten om vilken information vi samlar in om dig och hur vi hanterar den för att kunna erbjuda dig våra tjänster.
					<br /><br />
					Har du några frågor eller funderingar? Hör då av dig till oss på dataskydd@strollysweden.com
				</p>
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Vilken information samlar vi in om dig?
				</h3>

				<p className="mt-4">
					Vi på Strolly värnar om våra kunder, vilket innebär att vi alltid ser till att samla in personlig information ansvarsfullt med hänsyn till din integritet. Det är viktigt för oss att du får reda på vilken information vi samlar in om dig samt hur vi gör det.
					<br /><br />
					För att vi ska kunna erbjuda våra tjänster, som till exempel vår kundportal behöver vi samla in viss personlig information. Du kan komma att direkt, eller indirekt, behöva lämna information om dig själv på olika sätt. Exempel på när är:
					<br /><br />
					När du använder vår kundportal När du kontaktar oss eller besöker vår hemsida
					<br /><br />
					För dig som söker anställning hos Strolly behandlar vi dina personuppgifter i ett inledande skede för att kontrollera förekomsten i belastningsregistret. Vid erhållen anställning framgår behandlingen av personuppgifter enligt anställningsavtalet.
				</p>
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Kan jag begära en utskrift av mina personuppgifter?
				</h3>

				<p className="mt-4">
					Ja!
					<br /><br />
					Du kan begära ett utdrag över de personuppgifter vi har samlat in om dig. För att få ett sådant utdrag behöver du kontakta våra dataskyddshandläggare på dataskydd@strollysweden.com
				</p>
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Hur länge sparar ni mina personuppgifter?
				</h3>

				<p className="mt-4">
					Vi sparar dina uppgifter så länge som det behövs för att uppfylla de ändamål de samlats in för, exempelvis för att uppfylla våra avtalsförpliktelser gentemot dig eller våra berättigade intressen och tills dess att det inte längre finns några legala skyldigheter eller rättigheter för oss att ha kvar informationen.
				</p>
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Var lagrar ni mina personuppgifter?
				</h3>

				<p className="mt-4">
					Vi hanterar dina personuppgifter med största omsorg och ser till att de lagras tryggt och säkert. Vi strävar alltid efter att behandla dina uppgifter inom EU/EES. Uppgifterna kan dock under vissa omständigheter överföras till, och behandlas inom, ett land utanför EU/EES av ett en leverantör eller underleverantör. Eftersom Strolly är fast beslutet att alltid skydda dina uppgifter, kommer Strolly att vidta alla rimliga legala, tekniska och organisatoriska åtgärder för att säkerställa att dina uppgifter behandlas på ett säkert sätt och med en adekvat skyddsnivå i linje med, skyddsnivån som erbjuds inom EU/EES-området.
				</p>
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Hur avregistrerar jag mig från att få marknadsutskick?
				</h3>

				<p className="mt-4">
					Välj att avregistrera dig från våra nyhetsbrev genom att klicka på länken längst ner i mejlet.
				</p>
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Vad används mina personuppgifter till?
				</h3>

				<p className="mt-4">
					Vi hanterar de uppgifter som behövs för att kunna erbjuda dig våra tjänster och produkter –
				</p>
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Kan jag begära att mina personuppgifter blir borttagna?
				</h3>

				<p className="mt-4">
					Ja, det kan du!
					<br /><br />
					Det finns dock vissa begränsningar till “rätten att bli borttagna”. Vi har rättsliga skyldigheter som hindrar oss från att omedelbart radera viss del av din data. Denna skyldighet grundar sig bland annat i bokförings- och skattelagstiftning, och konsumentskyddslagstiftning.
					<br /><br />
					I samband med att du blir kund hos Strolly samtycker du till att Strolly använder dina personuppgifter i syfte att erbjuda dig specifika och relevanta erbjudanden om produkter och tjänster som Strolly tillhandahåller. Denna behandling har samtycke som grund vilket innebär att du när som helst kan återkalla ditt samtycke. Strolly kommer då att inte längre behandla dina personuppgifter detta ändamål.
					<br /><br />
					För att begära att få dina uppgifter bortglömda eller återkalla samtycke behöver du kontakta våra dataskyddshandläggare på dataskydd@strollysweden.com
				</p>
			</section>
		</div>
	);

};

export default PrivacyPolicy;