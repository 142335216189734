import { useId, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from 'UI'


const Checkbox = ({ className, label, ...props }) => {
	const id = useId();
	const [checked, setChecked] = useState(props.input != null ? Boolean(props.input.value) : Boolean(props.value));

	const onChange = () => {
		setChecked(!checked);

		if (props.onChange != null && typeof props.onChange == 'function') {
			props.onChange(!checked);
		}

		if (props.input != null) {
			if (props.input.onChange != null && typeof props.input.onChange == 'function') {
				props.input.onChange(!checked);
			}
		}
	};

	useEffect(() => {
		if (props.value != checked) {
			setChecked(Boolean(props.value));
		}
	}, [props.value])

	return (
		<div className={`inline-flex items-center relative ${className}`}>
			<input
				value={checked}
				type="checkbox"
				id={id}
				className={cx(
					'hidden',
					'peer',
				)}
				{...props}
				onChange={onChange}
			/>

			<label
				className={cx(
					'duration-300',
					'transition-all',
					'h-7',
					'w-7',
					'rounded-md',
					'bg-white',
					'border',
					'border-gray-300',
					'cursor-pointer',
					'peer-disabled:cursor-not-allowed',
					'peer-disabled:opacity-60',
					{
						'border-green': checked,
					}
				)}
				htmlFor={id}
			>
				<span
					className={cx(
						'duration-300',
						'transition-all',
						'w-full',
						'h-full',
						'flex',
						'items-center',
						'justify-center',
						'opacity-0',
						'scale-50',
						'text-green',
						{
							'opacity-100': checked,
							'scale-100': checked,
						}
					)}
				>
					<Icon name="Checkmark" />
				</span>
			</label>

			{label != null ?
				<label
					htmlFor={id}
					children={label}
					className="ml-2 cursor-pointer"
				/>
				: null}
		</div>
	);

};

Checkbox.defaultProps = {
	className: '',
	disabled: false,
	label: null,
};

Checkbox.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.string,
};

export default Checkbox;