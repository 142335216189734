import React from 'react';

const ProductMobileTitle = ({ stroller, price }) => <>
	<div className="block md:hidden mt-8">
		<h1 className="text-4xl">{stroller.name}</h1>

		{price != null ?
			<div className="text-gray-900 mt-2">
				Från <span className="font-semibold">{price.price}:- / mån</span>
			</div>
		: null}

		<div className="my-8 border-b border-gray-300" />
	</div>
</>;

ProductMobileTitle.position = 'before_description';

export default ProductMobileTitle;