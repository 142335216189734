import { Skeleton } from 'UI';

const StrollerCardPlaceholder = () => {

	return (
		<div className="flex flex-col flex-1">
			<Skeleton className="flex-1 mb-4 pt-96" />

			<Skeleton className="mb-4" />

			<Skeleton className="w-1/2" />
		</div>
	);

};

export default StrollerCardPlaceholder;
