import { Link } from 'react-router-dom';
import { Icon } from 'UI';

const Menu = ({ onNavigate, onLogin }) => {

	const itemClassnames = "flex items-center justify-between py-6 border-b border-gray-300 font-semibold cursor-pointer";
	const onLinkClick = () => {
		onNavigate();
	};

	return (
		<div>
			<Link onClick={onLinkClick} to="/" className={itemClassnames}>
				<div className="text-xl">
					Hem
				</div>

				<Icon name="Chevron" className="-rotate-90" />
			</Link>

			<Link onClick={onLinkClick} to="/barnvagnar" className={itemClassnames}>
				<div className="text-xl">
					Barnvagnar
				</div>

				<Icon name="Chevron" className="-rotate-90" />
			</Link>

			<Link onClick={onLogin} to="#" className={itemClassnames}>
				<div className="text-xl">
					Mitt Strolly
				</div>

				<Icon name="Chevron" className="-rotate-90" />
			</Link>

			<Link onClick={onLinkClick} to="/faq" className={itemClassnames}>
				<div className="text-xl">
					Vanliga frågor
				</div>

				<Icon name="Chevron" className="-rotate-90" />
			</Link>
			
			<Link onClick={onLinkClick} to="/kontakta-oss" className={itemClassnames}>
				<div className="text-xl">
					Kontakta oss
				</div>

				<Icon name="Chevron" className="-rotate-90" />
			</Link>
			
			{/* <Link onClick={onLinkClick} to="/about" className={itemClassnames}>
				<div className="text-xl">
					Om oss
				</div>

				<Icon name="Chevron" className="-rotate-90" />
			</Link> */}

			<Link onClick={onLinkClick} to="/villkor" className={itemClassnames}>
				<div className="text-xl">
					Villkor
				</div>

				<Icon name="Chevron" className="-rotate-90" />
			</Link>

			<Link onClick={onLinkClick} to="/integritetspolicy" className={itemClassnames}>
				<div className="text-xl">
					Integritetspolicy
				</div>

				<Icon name="Chevron" className="-rotate-90" />
			</Link>
		</div>
	);

}

export default Menu;