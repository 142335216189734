import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAnimatedDigit } from 'hooks/animation';

const AnimatedDigit = ({ duration, children }) => {
	const [currentValue, updateValue] = useAnimatedDigit(parseInt(children, 10), duration);

	useEffect(() => {
		updateValue(parseInt(children, 10));
	}, [children]);

	return (
		<span>
			{currentValue}
		</span>
	);
};

AnimatedDigit.defaultProps = {
	duration: 350,
	children: 0,
};

AnimatedDigit.propTypes = {
	duration: PropTypes.number,
	children: PropTypes.number,
};

export default AnimatedDigit;
