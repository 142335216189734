import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDeviceType } from 'hooks/device';
import { userApi } from 'services/user';

import QRCode from "react-qr-code";
import { Button } from 'UI';

const BankIdSignForm = (props) => {
	const [getLoginStatus, loginStatus] = userApi.endpoints.getUserLoginStatus.useLazyQuery();
	const [createLogin, loginSession] = userApi.endpoints.createLoginSession.useLazyQuery();
	const [displayQR, setDisplayQR] = useState(false);
	const [loading, setLoading] = useState(false);
	const deviceType = useDeviceType();

	const STATUS_DELAY = 1500;
	let interval = null;

	useEffect(() => {
		// On mount
		if (deviceType == 'iOS' || deviceType == 'Android' || deviceType == 'Windows Phone') {
			if (props.reference == null) {
				createLogin({});
			}
		}

		if (props.reference != null) {
			setLoading(true);
			interval = setInterval(() => {
				getLoginStatus(props.reference);
			}, STATUS_DELAY);

			return () => clearInterval(interval);
		}
	}, [deviceType]);

	useEffect(() => {
		if (loginStatus.data != null && loginStatus.data.status != "pending") {
			if (loginStatus.data.status == 'complete') {
				props.onComplete(loginStatus.data);
			} else {
				props.onError(loginStatus.data);
				setLoading(false);
			}

			return () => clearInterval(interval);
		}
	}, [loginStatus.data]);

	useEffect(() => {
		if (loginSession.data != null) {
			if (displayQR) {
				interval = setInterval(() => {
					getLoginStatus(loginSession.data.orderRef);
				}, STATUS_DELAY);

				return () => clearInterval(interval);
			}
		}
	}, [loginSession.data]);

	const openBankIdAuthentication = (event) => {
		event.preventDefault();
		const { autoStartToken, orderRef } = loginSession.data;

		window.location = 'https://app.bankid.com/?autostarttoken=' + autoStartToken + '&redirect=null';

		setLoading(true);
		interval = setInterval(() => {
			getLoginStatus(orderRef);
		}, STATUS_DELAY);
	};

	const onTriggerLogin = (event) => {
		event.preventDefault();
		setDisplayQR(true);
		createLogin({});
	};

	const getUserHint = () => {
		const data = loginStatus.data == null ? loginSession.data : loginStatus.data;

		if (data == null || data.status == 'complete') {
			return null;
		}

		if (data.errorCode != null) {
			if (data.errorCode == 'cancelled') {
				return 'Åtgärden avbruten. Försök igen';
			}

			if (data.errorCode == 'alreadyInProgress') {
				return 'En identifiering eller underskrift för det här personnumret är redan börjad. Försök igen.';
			}

			if (data.errorCode == 'requestTimeout' || data.errorCode == 'maintenance' || data.errorCode == 'internalError') {
				return 'Intern tekniskt fel. Försök igen.';
			}
		}

		if (data.status == 'pending') {
			if (data.hintCode == 'noClient' || data.hintCode == 'outstandingTransaction') {
				return 'Starta BankID-appen';
			}

			if (data.hintCode == 'userSign') {
				return 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.';
			}
		}

		if (data.status == 'failed') {
			if (data.hintCode == 'userCancel') {
				return 'Åtgärden avbruten.';
			}

			if (data.hintCode == 'expiredTransaction') {
				return 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din bank. Försök sedan igen.';
			}

			if (data.hintCode == 'startFailed') {
				return 'Intern tekniskt fel. Försök igen.';
			}
		}
	};

	const responseData = loginStatus.data == null ? loginSession.data : loginStatus.data;

	if (loading) {
		return (
			<div className="mt-spacing text-center">
				Väntar på svar från Bank-ID...
			</div>
		);
	};

	return (
		<div className="flex justify-center flex-col text-center p-10">
			<Button onClick={onTriggerLogin}>
				Bank-ID på annan enhet
			</Button>

			<Button disabled={loginSession.data == null} onClick={openBankIdAuthentication} className="mt-4">
				Bank-ID på den här enheten
			</Button>

			{responseData != null && displayQR ?
				<div className="mt-12 mx-auto border border-gray-700 p-6 rounded-lg w-50 h-50">
					<div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "100%" }}>
						<QRCode
							size={500}
							style={{ height: "auto", maxWidth: "100%", width: "100%" }}
							value={responseData.qrData}
							viewBox={`0 0 500 500`}
						/>
					</div>

					<div className="text-center">
						{getUserHint()}
					</div>
				</div>
				: null}
		</div>
	);

};

BankIdSignForm.defaultProps = {
	type: "",
	reference: null,
	onComplete: () => null,
	onError: () => null,
};

BankIdSignForm.propTypes = {
	type: PropTypes.string,
	reference: PropTypes.string,
	onComplete: PropTypes.func,
	onError: PropTypes.func,
};

export default BankIdSignForm;