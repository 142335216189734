import { Fragment } from 'react';
import { Expandable } from 'UI';

const ProductAnswers = () => {

	return (
		<Fragment>
			<Expandable trigger="En produkt jag önskar finns inte i sortimentet">
				Vi kommer göra vårt bästa för att hjälpa dig! Om den vara du letar efter inte finns i vårt sortiment kan du fylla i ett formulär och önska att vi tar hem den. Formuläret finns längst ned i vagnparken på vår hemsida.
			</Expandable>
		</Fragment>
	);

};

ProductAnswers.position = 'products';

export default ProductAnswers;
