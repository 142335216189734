
/**
 * @param {number} price 
 * @param {number} percentage 
 */
export const subtractPercentage = (price, percentage) => {
	return price * (1 - percentage / 100);
};

/**
 * @param {number} price 
 * @returns {string} price
 */
export const formatPrice = (price) => {
	return Math.round(price) + ':-';
};

/**
 * @param {object} product 
 * @returns {string} price
 */
export const getPriceHTML = (product) => {
	if (product.regularPrice == null || product.price == product.regularPrice) {
		return formatPrice(product.price);
	}

	return (
		<>
			<span className="text-red font-semibold">
				{formatPrice(product.price)}
			</span>

			<span className="line-through ml-1 text-gray-700">
				{formatPrice(product.regularPrice)}
			</span>
		</>
	);
};