
import { useState } from 'react';
import { Button, Checkbox, Modal } from 'UI';
import TermsPage from 'containers/Pages/Terms';

const TermsForm = (props) => {
	const [modal, setModal] = useState(false);
	const [checked, setChecked] = useState(false);

	const onCheckboxChange = (checked) => {
		setChecked(checked);
		setModal(checked);

		if (!checked) {
			if (props.onSubmit != null) {
				props.onSubmit(false);
			}
		}
	};

	const onAccept = (event) => {
		event.preventDefault();

		setModal(false);

		if (props.onSubmit != null) {
			props.onSubmit(true);
		}
	};

	const onDecline = (event) => {
		event.preventDefault();

		setModal(false);
		setChecked(false);

		if (props.onSubmit != null) {
			props.onSubmit(false);
		}
	};

	return (
		<div>
			<Modal open={modal} type="small" title="Villkor" onClose={onDecline}>
				<div className="p-10 -mt-12 md:-mt-24">
					<TermsPage />

					<div>
						<div className="my-10 border-b border-gray-300 w-full"></div>

						<Button onClick={onAccept} className="w-full">
							Jag godkänner villkoren
						</Button>

						<Button variant="green-outline" onClick={onDecline} className="w-full mt-2">
							Jag godkänner inte villkoren
						</Button>
					</div>
				</div>
			</Modal>

			<Checkbox
				label="Jag godkänner villkoren"
				onChange={onCheckboxChange}
				value={checked}
			/>
		</div>
	);

};

export default TermsForm;