import React from 'react';

const Burger = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/>
		<rect x="3" y="11" width="18" height="2" rx=".95" ry=".95"/>
		<rect x="3" y="16" width="18" height="2" rx=".95" ry=".95"/>
		<rect x="3" y="6" width="18" height="2" rx=".95" ry=".95"/>
	</svg>
)

export default Burger;