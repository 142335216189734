import React, { useMemo, useId } from 'react';
import cx from 'classnames';
import { Form, Field } from 'react-final-form';
import { useGetUserPublicInformationMutation } from 'services/user';
import { required, personalNumber, email, phone } from 'lib/validation';

import { Button, Icon, Input } from 'UI';

const CustomerCheckoutForm = (props) => {
	const formId = useId();
	const [getPublicInformation, { data: publicInformation }] = useGetUserPublicInformationMutation();

	const initialValues = useMemo(() => ({
		name: publicInformation?.fullName ?? '',
		address1: publicInformation?.addressRow1 ?? '',
		postalCode: publicInformation?.postalCode ?? '',
		city: publicInformation?.postalArea ?? '',
	}), [publicInformation]);

	const submitForm = () => {
		const submitEvent = new Event('submit', {
			bubbles: true,
			cancelable: true,
		});

		document.getElementById(formId).dispatchEvent(submitEvent);
	};

	const onSubmit = (values) => {
		return new Promise((resolve) => {
			if (props.onSubmit != null && typeof props.onSubmit === 'function') {
				props.onSubmit(values).then(() => {
					resolve(1);
				});

				return true;
			}

			resolve(1);
		});
	};

	const onInputBlur = (event) => {
		event.preventDefault();
		submitForm();
	};

	const fetchInfo = async (event, fieldState) => {
		event.preventDefault();

		if (fieldState != null && fieldState.valid) {
			await getPublicInformation(fieldState.value);
			submitForm();
		}
	};

	const fieldProps = {
		component: Input,
		onBlur: onInputBlur,
		validate: required,
	};

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={initialValues}
			keepDirtyOnReinitialize={true}
			render={({ handleSubmit, submitting, form }) => {
				const emailField = form.getFieldState('email');
				const phoneField = form.getFieldState('phoneNumber');
				const personalNumberField = form.getFieldState('personalNumber');

				const nextEnabled = emailField?.valid && phoneField?.valid && personalNumberField?.valid;

				return (
					<form id={formId} onSubmit={handleSubmit}>

						<div className="flex flex-col md:flex-row gap-2">
							<div className="w-full">
								<Field
									{...fieldProps}
									label="Mejladress"
									name="email"
									validate={email}
								/>
							</div>

							<div className="w-full">
								<Field
									{...fieldProps}
									label="Telefonnummer"
									name="phoneNumber"
									validate={phone}
								/>
							</div>
						</div>

						<div className="mt-3">
							<Field
								{...fieldProps}
								label="Personnummer"
								name="personalNumber"
								validate={personalNumber}
								placeholder="T.ex. 199501015533"
							/>
						</div>

						{publicInformation == null ?
							<div className="mt-4">
								<Button
									variant='black'
									disabled={!nextEnabled}
									onClick={(e) => fetchInfo(e, personalNumberField)}
								>
									Nästa <Icon name="Arrow" className="ml-2" />
								</Button>
							</div>
						: null}

						<div
							className={cx({
								'block': publicInformation != null,
								'hidden': publicInformation == null,
							})}
						>
							<div className="mt-3">
								<Field
									{...fieldProps}
									label="Namn"
									name="name"
								/>
							</div>

							<div className="mt-3 flex gap-2">
								<div className="flex-1">
									<Field
										{...fieldProps}
										label="Adress"
										name="address1"
									/>
								</div>

								<div className="flex-1">
									<Field
										{...fieldProps}
										label="Postnummer"
										name="postalCode"
									/>
								</div>
							</div>

							<div className="mt-3">
								<Field
									{...fieldProps}
									label="Ort"
									name="city"
								/>
							</div>
						</div>

					</form>
				);
			}}
		/>
	);

};

export default CustomerCheckoutForm;