import React from 'react';

const Stroller = () => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 511.999 511.999">
		<g>
			<path className="fill" d="M416.001,405.329c-12.716,0-24.403,4.479-33.579,11.938l-66.955-55.021l32.703-26.874
				c43.178-0.984,83.733-18.368,114.574-49.207c31.762-31.762,49.255-73.831,49.255-118.452c0-44.625-17.492-86.692-49.255-118.456
				C430.982,17.494,388.916,0.002,344.292,0.002h-45.625C292.776,0.002,288,4.779,288,10.669v146.377H105.595
				c-4.955-24.316-26.505-42.669-52.261-42.669H10.667C4.777,114.377,0,119.152,0,125.044c0,5.889,4.777,10.667,10.667,10.667
				h42.667c17.645,0,32,14.355,32,32.002c0,44.621,17.492,86.688,49.255,118.452c30.841,30.839,71.398,48.223,114.573,49.207
				l32.703,26.874l-66.952,55.019c-9.175-7.456-20.862-11.936-33.578-11.936c-29.407,0-53.334,23.927-53.334,53.336
				c0,29.407,23.927,53.332,53.334,53.332c29.409,0,53.334-23.924,53.334-53.332c0-9.001-2.249-17.48-6.202-24.924l70.2-57.688
				l70.203,57.688c-3.952,7.444-6.203,15.926-6.203,24.924c0,29.407,23.927,53.332,53.334,53.332
				c29.408,0,53.334-23.924,53.334-53.332C469.335,429.255,445.41,405.329,416.001,405.329z M181.334,490.663
				c-17.645,0-32-14.353-32-31.997c0-17.647,14.355-32.002,32-32.002s32,14.355,32,32.002
				C213.334,476.31,198.979,490.663,181.334,490.663z M309.335,21.336h34.958c8.91,0,17.635,0.804,26.11,2.335l-25.819,44.223
				c-2.97,5.088-1.254,11.619,3.834,14.591c1.692,0.988,3.542,1.456,5.369,1.456c3.668,0,7.239-1.893,9.222-5.29l28.83-49.382
				c54.226,18.674,94.138,68.364,98.438,127.775h-149.76l66.878-36.465c5.174-2.821,7.08-9.301,4.259-14.471
				c-2.82-5.174-9.3-7.078-14.472-4.26l-87.847,47.896V21.336z M253.04,314.085c-77.123-0.001-140.508-59.956-145.987-135.705
				h191.614h191.608c-5.482,75.737-68.843,135.688-145.951,135.705c-0.015,0-0.028,0-0.042,0H253.04z M298.666,348.438
				l-15.843-13.019h31.687L298.666,348.438z M416.001,490.663c-17.645,0-32-14.353-32-31.997c0-17.647,14.355-32.002,32-32.002
				c17.645,0,32,14.355,32,32.002C448.001,476.31,433.646,490.663,416.001,490.663z"/>
			<path className="fill" d="M429.472,107.072c1.732,0,3.49-0.422,5.116-1.314l0.657-0.361c5.167-2.831,7.061-9.315,4.23-14.48
				c-2.83-5.169-9.314-7.063-14.48-4.229l-0.658,0.361c-5.167,2.829-7.061,9.312-4.23,14.48
				C422.048,105.067,425.701,107.072,429.472,107.072z"/>
			<path className="fill" d="M217.555,199.71H177.89c-5.892,0-10.667,4.776-10.667,10.667c0,5.89,4.776,10.667,10.667,10.667h39.665
				c5.89,0,10.667-4.777,10.667-10.667C228.222,204.485,223.446,199.71,217.555,199.71z"/>
			<path className="fill" d="M147.247,199.714h-0.254c-5.892,0-10.667,4.776-10.667,10.667c0,5.889,4.776,10.667,10.667,10.667h0.254
				c5.892,0,10.667-4.778,10.667-10.667C157.914,204.489,153.139,199.714,147.247,199.714z"/>
			<path className="fill" d="M181.334,448.001c-5.873,0-10.667,4.785-10.667,10.663c0,5.878,4.794,10.669,10.667,10.669
				c5.876,0,10.665-4.792,10.665-10.669C191.999,452.787,187.21,448.001,181.334,448.001z"/>
			<path className="fill" d="M416.001,448.001c-5.873,0-10.665,4.785-10.665,10.663c0,5.878,4.792,10.669,10.665,10.669
				c5.875,0,10.667-4.792,10.667-10.669C426.669,452.787,421.876,448.001,416.001,448.001z"/>
		</g>
	</svg>
);

export default Stroller;