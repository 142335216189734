import React from 'react';
import PropTypes from 'prop-types';

import {
	A11y,
	Scrollbar,
} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';

const SwiperComponent = (props) => {

	return (
		<Swiper
			modules={[Scrollbar, A11y]}
			spaceBetween={props.spaceBetween}
			slidesPerView={props.slidesPerView}
			pagination={{ clickable: true }}
			scrollbar={{ draggable: true }}
			{...props}
		>
			{props.children?.map((child, index) => {
				return <SwiperSlide key={`slide-${index}`}>
					{child}
				</SwiperSlide>
			})}
		</Swiper>
	);

}

SwiperComponent.defaultProps = {
	spaceBetween: 0,
	slidesPerView: 'auto',
};

SwiperComponent.propTypes = {
	spaceBetween: PropTypes.number,
	slidesPerView: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.oneOf(['auto']),
	]),
	children: PropTypes.arrayOf(PropTypes.element),
};

export default SwiperComponent;