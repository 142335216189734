import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from 'classnames';
import { Button } from "UI";
import { acceptCookies } from "services/cookies";

const CookieNotice = () => {
	const dispatch = useDispatch();
	const accepted = useSelector((state) => state.cookies.accepted);
	const [more, setMore] = useState(false);

	const onAcceptClick = (event) => {
		event.preventDefault();
		dispatch(acceptCookies());
	};

	return (
		<div className={cx(
			"fixed",
			"bottom-12",
			"w-full",
			"z-[999999999999999]",
			{
				"hidden": accepted,
			}
		)}>
			<div className="w-10/12 xl:w-2/3 mx-auto bg-white rounded-md p-4 shadow-md border border-gray-200">
				<div className="flex justify-between xl:items-center flex-col xl:flex-row">
					<div className="flex">
						<div className="text-5xl">
							🍪
						</div>

						<div className="ml-4">
							<h3 className="text-xl font-semibold">
								Denna webbplats använder cookies
							</h3>

							<p className="mt-2 text-sm">
								Vi använder enhetsidentifierare för att anpassa innehållet och annonserna till användarna, tillhandahålla funktioner för sociala medier och analysera vår trafik.

								{more ?
									' Vi vidarebefordrar även sådana identifierare och annan information från din enhet till de sociala medier och annons- och analysföretag som vi samarbetar med. Dessa kan i sin tur kombinera informationen med annan information som du har tillhandahållit eller som de har samlat in när du har använt deras tjänster.'
									: '..'}

								<span className="text-blue cursor-pointer decoration hover:underline ml-1" onClick={() => setMore(!more)}>
									{more ?
										'Visa mindre'
										: 'Visa mer'}
								</span>
							</p>
						</div>
					</div>

					<div className="mt-4 xl:mt-0 xl:ml-6">
						<Button variant="black" onClick={onAcceptClick}>
							Tillåt cookies
						</Button>
					</div>
				</div>
			</div>
		</div>
	);

};

export default CookieNotice;
