import { usePage } from "hooks/page";

const Terms = () => {
	const [registerPosition, SEO] = usePage('terms');

	return (
		<div className="mt-spacing">
			<SEO>
				<title>Villkor</title>
			</SEO>

			<h1 className="text-4xl font-semibold">Villkor</h1>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						1. Tillämplighet
					</li>

					<li className="mb-2 last-of-type:mb-0">
						1.1. Dessa Allmänna Villkor skall tillämpas när Leverantören hyr ut barnvagnar eller andra produkter till Kund.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						1.2. Om parterna skriftligen har avtalat om villkor som avviker från vad som föreskrivs i dessa villkor skall parternas särskilda överenskommelse gälla i de delarna.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						1.3. Med ”Kontraktssumman” förstås i dessa Allmänna Villkor summan av samtliga hyror som Kund har skyldighet att erlägga under hyrestiden enligt detta Avtal.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						1.4 Med “Hyresobjekt” eller “Hyresobjekten” förstås i dessa Allmänna Villkor som samlingsnamnet för samtliga hyrda produkter.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						2. Äganderätt m.m.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						2.1. Hyresobjektet förblir Leverantörens egendom under hela hyresperioden och Kund förvärvar inte på grund av detta hyresavtal någon äganderätt till Hyresobjektet.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						2.2. Hyresobjektet skall vara försett med en av Leverantören tillhandahållen skylt, som utvisar att det är Leverantörens egendom. Kund skall omgående underrätta Leverantören om den märkning, som utvisar Leverantören som ägare till Hyresobjektet, förstörts eller eljest blivit oläslig.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						2.3. Kund får inte själv eller genom annan pantsätta, sälja, upplåta rättigheter i, eller på annat sätt avhända sig Hyresobjektet eller ändra, förstöra eller montera bort märke, skylt, ingjutning eller annan markering på Hyresobjektet.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						2.4. Leverantören har rätt att när som helst under hyrestiden besiktiga Hyresobjektet i form av fotografier på Hyresobjektet av Kund.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						3. Hyra och betalning m.m.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						3.1. Hyra utgår enligt vad som avtalats mellan parterna på sida ett (1) och enligt vad som anges i punkt fyra (4). Hyran gäller inklusive mervärdesskatt. Faktureringsavgifter tillkommer utöver hyran om så markerat på sida ett (1).
					</li>

					<li className="mb-2 last-of-type:mb-0">
						3.2. Kund äger inte rätt att hålla inne eller avräkna betalning till Leverantören med anledning av eventuella motkrav gentemot Leverantören som grundar sig i Leverantörens förpliktelser enligt detta Avtal. Kund förklarar sig därför avstå från rätt att hålla inne eller avräkna hyresbetalning mot krav grundade på Leverantörens åtaganden enligt detta Avtal.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						3.3. Vid dröjsmål med erläggande av hyra skall Kund betala dröjsmålsränta från förfallodagen till dess full betalning erläggs. Dröjsmåls- ränta enligt räntelagen enligt en räntesats motsvarande diskontot för aktuell tidpunkt med ett tillägg av åtta (8) procentenheter.. Om Leverantören så påfordrar skall Kund ersätta Leverantörens kostnader för betalningspåminnelser.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						3.4. Leverantören äger rätt att sälja Hyrestagarens fakturor till andra part.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						3.4a Leverantören äger rätten att sälja/ förlägga obetalda fakturor till annan part såsom inkasso, kronofogden eller annan övrig part.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						3.5a. Leverantörer samarbetar med Resurs Bank.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						3.5b. Hyrestagaren har samma skyldigheter som i detta avtal oavsett om Leverantören har sålt eller väljer att fakturera i samarbete med Resurs Bank.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						3.5c. Vid godkännande av Resurs Banks villkor godkänner Hyrestagaren automatiskt dessa villkor.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						3.6a. Om Hyrestagaren inte betalar sina fakturor eller på krav av Leverantörer lämnar tillbaka Hyresobjektet klassas detta som stöld och Leverantören har då även rätt att göra en anmälan till Polisen.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						3.6b. Leverantören äger också rätten att skicka en slutfaktura på Hyresobjektet. Priset för Hyresobjektet regleras på förlorad övrig vinst (30%), värdet av Hyresobjektet vid ren försäljning mot slutkund. Det vill säga att en produkt som sälj för 5000kr i allmänhet på marknaden mot slutkund är värd 5000kr X 1,3 = 6500kr.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						3.6c. I värderingen av slutfaktura enligt punkt 3.6.b har leverantören även rätt att ta ut en avgift för emballering, emballage, förslitningar på Hyresobjektet och även frakt.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						4. Justering av hyran
					</li>

					<li className="mb-2 last-of-type:mb-0">
						4.1. Leverantören äger rätt att höja de ovan avtalade beloppet-pen på sida ett (1). Sådan höjning ska meddelas Kunden skriftligen senast en (1) månad i förväg. Leverantören äger även rätt att, i det fall sociala avgifter för personal eller andra skatter eller avgifter ändras under avtalsperioden, från den dag ändringen inträtt, justera sagda belopp i motsvarande mån. Leverantören ska i så fall omedelbart informera Kunden om förändringen.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						4.2. Anmärkningar på fakturor ska skriftligen vara Leverantören tillhanda senast femton (15) dagar efter fakturadatum.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						5. Hyresobjektets skötsel och brukande
					</li>

					<li className="mb-2 last-of-type:mb-0">
						5.1. Under hyresperioden skall Kund väl vårda Hyresobjektet så att det inte försämras utöver vad som är att anse som normal förslitning.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						5.2 Kund har ingen rätt att kräva ersättning för ett välvårdat Hyresobjekt. Leverantör äger rätten att välja ersättning och om ersättning skall utgå till Kund för ett välvårdat Hyresobjekt.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						6. Vissa förbud för Kunden m.m.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						6.1. Kund får inte utan Leverantörens skriftliga medgivande<br />

						<div className="my-2 mx-8">
							a) genomföra ändringar på Hyresobjektet;<br />
							b) hyra ut Hyresobjektet till annan part; eller<br />
							c) överlåta eller på annat sätt förfoga över detta Avtal.<br />
						</div>
					</li>

					<li className="mb-2 last-of-type:mb-0">
						6.2. Om rättslig åtgärd skulle vidtas beträffande Hyresobjektet under hyrestiden, såsom utmätning eller kvarstad skall Kund för utmätningsmannen visa upp sitt exemplar av detta Avtal samt upplysa utmätningsmannen om Leverantörens rätt till Hyresobjektet. Kund skall därefter omedelbart skriftligen underrätta Leverantören om den av utmätningsmannen vidtagna åtgärden.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						7. Kundens nyttjanderätt till Hyresobjektet
					</li>

					<li className="mb-2 last-of-type:mb-0">
						7.1. Leverantören skall respektera Kundens nyttjanderätt till Hyresobjektet och skall under hyresperioden avhålla sig från sådana åtgärder och ingripanden som stör Kundens brukande av Hyresobjektet, såvida detta inte är påkallat av underhålls- eller säkerhetsskäl eller i övrigt följer av Avtalet.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						8. Service och Underhåll
					</li>

					<li className="mb-2 last-of-type:mb-0">
						8.1. För dess att Hyresobjektet däck eller andra delar förslitits och av säkerhet eller funktionsskäll behöver bytas är Leverantören skyldig att erhålla reservdelar till Kund.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						8.2. Kund skall omedelbart underrätta Leverantören för det fall att Hyresobjektet är i behov av Service och/eller Underhåll.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						8.3. Leverantören har rätt att kräva ersättning av reservdelar i den form av att Kund har varit oaktsam eller vid rimliga skäl till misstanke om oaktsamhet.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						8.4. Kund har ingen rätt att kräva service eller byte av reservdelar på plats av Leverantör.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						8.5. Kund har rätt att kräva service av Hyresobjektet av Leverantör hos Leverantörens angivna servicestation.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						8.6 Kund håller ansvaret för eventuella leveransskador i följd av punkt 8.5
					</li>

					<li className="mb-2 last-of-type:mb-0">
						8.7. Om Leverantör inte kan erbjuda reservdelar har Leverantören rätt att byta Hyresobjektet mot ett likvärdigt Hyresobjekt. Kan Leverantör inte erbjuda ett liknande Hyresobjekt har Kund rätt att avsäga sig avtalet.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						9. Leverans och leveransförsening
					</li>

					<li className="mb-2 last-of-type:mb-0">
						9.1. Vid Leveransförsening har Kund att godta en i övrigt korrekt leverans utan att erhålla någon rätt till ersättning från Leverantören i anledning av förseningen. Kund har dock inte skyldighet att erlägga hyra för den tid som leveransen är försenad.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						10. Fel i Hyresobjektet
					</li>

					<li className="mb-2 last-of-type:mb-0">
						10.1. Om Kund anser att Hyresobjektet inte instämmer sig med Leverantörens 3-star policy skall Kund meddela Leverantör skriftligen inom femton (15) dagar efter leverans tillsammans med fotografier av Hyresobjektet.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						10.1.1. Kund äger inte rätten att kräva en prissänkning av Hyresobjektet.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						10.1.2. Om Kund anser att Hyresobjektet inte stämmer in med Leverantörens 3-star policy har Kund rätt att kräva en ombedömning från Leverantören.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						10.2. Vid mottagandet av Hyresobjektet skall Kund genast noggrant undersöka Hyresobjektet.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						10.3. Fel som upptäckts eller borde ha upptäckts vid Kundens undersökning enligt punkt 10.2 skall reklameras genom skriftligt meddelande inom femton (15) dagar från mottagandet av Hyresobjektet. Övriga fel skall reklameras genom sådant meddelande inom femton (15) dagar räknat från det att felet visade sig. Reklamerar Kundens på grund av fel och det visar sig att Leverantören inte är ansvarig för felet har Leverantören rätt till ersättning för de kostnader reklamationen orsakat honom.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						10.4 Leverantören här när som helst rätt att återkalla hyresobjektet och dess tillbehör vid misstanke om att varan/varorna skulle vara defekta eller på annat sätt kunna riskera att skada.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						11. Förlust av eller skada på Hyresobjektet
					</li>

					<li className="mb-2 last-of-type:mb-0">
						11.1. Kundens bär – oberoende av om eget vållande föreligger eller inte – ensam ansvaret för förlust eller skada av-på Hyresobjektet. Sådan skada eller förlust befriar inte Kundens från sin skyldighet att erlägga hyra eller från övriga förpliktelser enligt detta Avtal.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						11.2. Om Hyresobjektet skadas, förstörs eller förloras så att det blir obrukbart åligger det Kundens att genast underrätta Leverantören härom.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						11.3. Av punkt 11.1 och 11.2 är Kunden skyldig att ersätta Leverantör om en ersättning på ett (1) års förlorade hyresintäkter av hyresbeloppet på sida ett (1).
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						12. Försäkringar
					</li>

					<li className="mb-2 last-of-type:mb-0">
						12.1. Kund skall ha tecknat hemförsäkring. Hemförsäkringen täcker de vanligaste förekommande skadorna, stöld eller förlust.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						12.2. Vid försäkringsfall skall Kund omedelbart underrätta Leverantören härom.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						13. Ansvar för person- och sakskada
					</li>

					<li className="mb-2 last-of-type:mb-0">
						13.1. Leverantören ansvarar inte för sakskada som Hyresobjektet orsakar på fast eller lös egendom om skadan inträffar då Hyresobjektet är i Kundens besittning.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						13.2. Leverantörens ansvarar inte för skador som Hyresobjektet orsakar på person eller på fast eller lös egendom som tillhör Kund eller tredje man.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						13.3. Framställer tredje man krav mot Leverantören eller Kund på ersättning för skada eller förlust som avses i 13.2 skall andra parten genast underrättas därom.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						13.4. Leverantören och Kund är skyldiga att låta sig instämmas till den domstol eller skiljenämnd som behandlar ersättningskrav mot någon av dem, om kravet grundas på skada eller förlust som påstås vara orsakad av det levererade Hyresobjektet. Det inbördes förhållandet mellan Leverantören och Kund skall dock alltid avgöras på sätt som sägs i detta avtal.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						14. Skadestånd och ansvarsbegränsningar
					</li>

					<li className="mb-2 last-of-type:mb-0">
						14.1. Leverantör svarar inte i något fall för ren förmögenhetsskada, indirekta skador eller förluster, eller följdskador såsom exempelvis produktionsbortfall, utebliven vinst eller förlust i näringsverksamhet.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						14.2 Vid ett eventuellt åtgärdande är det enbart skadan som åtgärdas. Kunden är inte berättigad till värdehöjande åtgärder.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						15. Avtalstid
					</li>

					<li className="mb-2 last-of-type:mb-0">
						15.1. Detta avtal gäller från undertecknandet med en bindningstid om 6 eller 12 månader. Efter bindningstiden gäller en uppsägnings- tid om 1 kalendermånad dvs. vid månadsskifte. Uppsägning ska ske skriftligen via mejl eller via hemsidan under “mina sidor”.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						15.2 Avtalstiden har bestämts i samband med godkännandet av avtalet och kan inte ändras i efterhand.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						16. Byte och Retur
					</li>

					<li className="mb-2 last-of-type:mb-0">
						16.1 Avtalet är bindande till det specifika Hyresobjektet i bilaga 1- orderbekräftelse som tillkommer detta avtal.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						16.2 Vid retur av produkt efter meddelad uppsägningstid erhåller Leverantör fraktsedel tillsammans med betalt porto.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						16.3 Om Leverantör efterfrågar har kund skyldighet att uppvisa kvitto för retur av produkt om 2 månader efter att kund mottagit det.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						16.3a Om Kund inte kan påvisa att produkten är returnerad kan Kund komma att bli skadeståndsskyldig för produkten
					</li>

					<li className="mb-2 last-of-type:mb-0">
						16.4 Kunden äger ansvaret för att produkten/ produkterna under leveransen tillbaka till Leverantör.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						16.4.a Om produkt under leverans tillbaka till Leverantör skadas äger kunden ansvaret att ersätta skadan.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						16.5.Om kund säger upp avtalet under ångerrätten (14 dagar från tecknandet av avtalet) har Leverantören rätt att ta betalt i enlighet med punkt 16.4 samt även för fraktkostnaden av emballering och hyresobjektets fraktkostnader.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						17. Uppsägning på grund av avtalsbrott
					</li>

					<li className="mb-2 last-of-type:mb-0">
						17.1. Vardera parten äger rätt att med omedelbar verkan säga upp detta avtal;
					</li>

					<li className="mb-2 last-of-type:mb-0">
						17.1.1. Om den andra parten i väsentligt avseende åsidosät- ter sina skyldigheter enligt detta avtal och ej vidtar full rättelse inom trettio (30) dagar efter skriftligt påpekan- de därom från den andra parten eller
					</li>

					<li className="mb-2 last-of-type:mb-0">
						17.1.2. om den andra parten försätts i konkurs, upptar ackordsförhandling, träder i likvidation, underlåter att behörigen infria icke tvistig förbindelse eller eljest kan antas ha kommit på obestånd.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						18. Förtida uppsägning
					</li>

					<li className="mb-2 last-of-type:mb-0">
						18.1. Leverantören har rätt att omedelbart säga upp detta Avtal och återta Hyresobjektet om<br />

						<div className="my-2 mx-8">
							a) Kund genom att inte fullgöra förpliktelse i detta Avtal äventyrar Leverantörens äganderätt eller värdet därav t.ex. genom vanvård av Hyresobjektet eller vägran att låta Leverantören besiktiga detsamma;<br />
							b) Kund i mer än tio dagar underlåtit att betala förfallen hyra eller andra avgifter enligt Avtalet; eller<br />
							c) Kund inställer betalningar, försätts i konkurs, eller eljest av Leverantören finnes vara på sådant obestånd, att det kan antas att hyran inte blir rätteligen erlagd.<br />
						</div>
					</li>

					<li className="mb-2 last-of-type:mb-0">
						18.2. Om Avtalet sägs upp av Leverantören av anledning som angetts i punkt 18.1 skall Leverantören ha rätt att erhålla – förutom hyra och andra förfallna belopp under uppsägningstiden även leveranskostnader av Hyresobjektet från Kund till Leverantör.¨
					</li>

					<li className="mb-2 last-of-type:mb-0">
						18.3 Kund har inte rätt att säga upp avtalet under bindningstiden om detta sker 14 dagar efter avtalets tecknande.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						19. Återställande av Hyresobjektet
					</li>

					<li className="mb-2 last-of-type:mb-0">
						19.1. Om Kund inte återlämnar Hyresobjektet vid uppsägningstidens slut skall Kund fortsätta att erlägga hyra med 1/30 per dag av den månatliga hyran under den tid som Hyresobjektet inte återställts till den av Leverantören angivna adressen enligt sida ett (1). För det fall Hyresobjektet inte är återlämnat inom trettio (30) dagar från och med hyresperiodens slut skall Kund erlägga ovan angiven hyra på sida ett (1) per månad.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						20. Personuppgiftslag
					</li>

					<li className="mb-2 last-of-type:mb-0">
						20.1. Leverantören använder de personuppgifter som Kunden lämnat i syfte att kunna uppfylla avtalet mot Kund så effektivt som möjligt och för att ha möjlighet att erbjuda Kund tilläggsprodukter samt lämna erbjudanden som utgör komplement till hyrobjektet. Förutom att Leverantören därvid behandlar personuppgifterna internt inom företaget, använder sig Leverantören även av utomstående företag som får del av personuppgifterna för att möjliggöra att ändamålet med de insamlade personuppgifterna fullgörs. Du kan läsa mer om detta hos Strollysweden.se. Har du frågor angående dataskydd kan du kontakta dataskydd@strollysweden.com
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						21. Force Majeure
					</li>

					<li className="mb-2 last-of-type:mb-0">
						21.1. Omständighet som förhindrar eller väsentligen försvårar fullgörandet av något av parternas åtaganden enligt Avtalet och som part inte kunnat råda över, såsom men inte begränsat till eldsvåda, jordbävning, översvämning, krig, mobilisering eller militärinkallelser av större omfattning, uppror eller upplopp, rekvisition, beslag, valutarestriktion, myndighetsbestämmelse, inskränkning i fråga om drivkraft, allmän knapphet på transporter, varor eller energi eller strejk, blockad, lockout eller annan arbetskonflikt oavsett om avtalsparten är part i konflikten eller ej samt även fel i eller försening av leverans från underleverantör p.g.a. omständigheter, som nu nämnts, skall utgöra befrielsegrund som ger rätt till erforderlig tidsförlängning och befrielse från vite och andra påföljder. Sådan befrielsegrund skall part skriftligen underrätta den andra parten om utan oskäligt uppehåll efter det att denne insåg eller bort inse att befrielsegrunden förelåg.
					</li>

					<li className="mb-2 last-of-type:mb-0">
						21.2. Om Avtalets fullgörande förhindras längre tid än sex månader p.g.a. omständigheter som nämns i punkt 21.1 skall vardera parten ha rätt att utan ersättningsskyldighet för skada eller annat frånträda detta Avtal.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						22. Tvist
					</li>

					<li className="mb-2 last-of-type:mb-0">
						22.1. Tvist angående tolkningen eller tillämpningen av detta avtal ska i första instans avgöras i Stockholms tingsrätt.
					</li>
				</ol>
			</section>

			<section className="mt-16 text-xl">
				<ol>
					<li className="mb-2 last-of-type:mb-0 font-semibold">
						23. Preskription
					</li>

					<li className="mb-2 last-of-type:mb-0">
						23.1. Anspråk mot Leverantören förfaller om domstols- eller skiljeförfarande inte inleds inom två (2) år från Hyresobjektets avlämnande.
					</li>
				</ol>
			</section>
		</div>
	);

};

export default Terms;