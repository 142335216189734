import React from 'react';
import cx from 'classnames';
import { getPublicURL } from 'lib/url';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'UI';

const Hero = () => (
	<div className="container-breakout h-hero bg-morninghaze">
		<div className="h-full md:h-full flex flex-col md:flex-row md:justify-between c--container-responsive">

			<div className={cx(
				"flex",
				"flex-col",
				"justify-center",
				"text-black",
				"w-full",
				"flex-1",
				"py-8",
				"px-8",
				"md:py-10 sm:px-0",
				"md:w-1/3",
				"md:pr-16",
			)}>
				<h1 className="text-3xl md:text-5xl mb-5 text-gray-800 font-semibold">
					Streama* en barnvagn direkt hos oss!
				</h1>

				<div className="text-sm md:text-base mb-5 text-gray-800">
					<strong className="mr-1">
						*
					</strong>
					Att ha sin barnvagn på abonnemang kallar vi för att streama. Spara tid, plats och pengar och bidra samtidigt till en mer hållbar värld!
				</div>

				<div>
					<Link className="inline-block" to="/barnvagnar">
						<Button variant="green">
							Utforska barnvagnar
							<Icon name="Chevron" className="-rotate-90 ml-2" />
						</Button>
					</Link>
				</div>
			</div>

			<div className={cx(
				"relative",
				"flex",
				"order-first",
				"md:order-last",
				"w-full",
				"h-3/5",
				"mt-0",

				"md:w-1/2",
				"md:h-full",
				"md:top-10",
			)}>
				<div
					className="h-full w-full bg-center bg-cover"
					style={{ backgroundImage: `url('${getPublicURL()}assets/closeup_baby.jpg')` }}
				/>
			</div>

		</div>
	</div>
);

Hero.position = 'hero';

export default Hero;