import React from 'react';
import './_icon.css';

// Import svgs
import _Arrow from './_svg/Arrow';
import _Bankid from './_svg/Bankid';
import _Burger from './_svg/Burger';
import _Checkmark from './_svg/Checkmark';
import _Chevron from './_svg/Chevron';
import _Close from './_svg/Close';
import _Collapse from './_svg/Collapse';
import _Expand from './_svg/Expand';
import _Funnel from './_svg/Funnel';
import _Star from './_svg/Star';
import _Star_Outline from './_svg/Star_outline';
import _Stroller from './_svg/Stroller';
import _Trash from './_svg/Trash';

// Define icons
export const icons = {
	arrow: _Arrow,
	bankid: _Bankid,
	burger: _Burger,
	checkmark: _Checkmark,
	chevron: _Chevron,
	close: _Close,
	collapse: _Collapse,
	expand: _Expand,
	funnel: _Funnel,
	star_outline: _Star_Outline,
	star: _Star,
	stroller: _Stroller,
	trash: _Trash,
};

const Icon = (props) => {
	let elemProps = {...props};
	let name = props.name.toLowerCase();
	if (props.bw == true) {
		name = name + '_bw';
	}

	const icon = icons[name];

	if (icon == null) {
		return null;
	}

	delete elemProps.bw;
	delete elemProps.className;

	return <span className={props.className != null ? `icon ${props.className}` : "icon"} {...elemProps}>
		{typeof(icon) == 'string' ?
			<img src={icon} alt={props.name} />
		: icon()}
	</span>
}

export default Icon;