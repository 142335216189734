import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	active: [],
};

export const filtersSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		toggleFilter: (state, action) => {
			const filterName = action.payload.page + '~' + action.payload.name + '~' + action.payload.filter;

			if (state.active.indexOf(filterName) > -1) {
				state.active.splice(state.active.indexOf(filterName), 1);
			} else {
				state.active.push(filterName);
			}
		},
		setFilter: (state, action) => {
			const filterName = action.payload.page + '~' + action.payload.name;
			const foundIndex = state.active.findIndex((entry) =>
				entry.indexOf(filterName) > -1
			);

			if (foundIndex > -1) {
				state.active.splice(foundIndex, 1);
			}

			state.active.push(filterName + '~' + action.payload.filter);
		},
		clearFilter: (state, action) => {
			const filterName = action.payload.page + '~' + action.payload.name;

			state.active = state.active.filter((entry) =>
				entry.indexOf(filterName) == -1
			);
		},
		clearFilters: (state, action) => {
			state.active = [];
		},
	},
});

export const {
	toggleFilter,
	setFilter,
	clearFilter,
	clearFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;