import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getApiUrl } from 'lib/env';
import { buildParams } from 'lib/url';

export const strollersApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: getApiUrl() + 'api/v1/'
	}),
	reducerPath: 'strollers',
	tagTypes: ['Accessories', 'FilterOptions', 'Strollers', 'Stroller'],
	endpoints: (build) => ({
		getStrollers: build.query({
			query: (params) => ({
				url: `strollers?${buildParams(params)}&pagecount=25`
			}),
			providesTags: (result, error, page) => [{ type: 'Strollers', id: 'PAGE'+page }],
		}),
		getLatestStrollers: build.query({
			query: () => ({
				url: `strollers?page=1&pagecount=6`
			}),
		}),
		getStroller: build.query({
			query: (id) => ({
				url: `strollers/${id}`
			}),
			providesTags: (result, error, id) => [{ type: 'Stroller', id: parseInt(id) }],
		}),
		getStrollerAccessories: build.query({
			query: (id) => ({
				url: `strollers/${id}/accessories`
			}),
			providesTags: (result, error, id) => [{ type: 'Accessories', productId: id }],
		}),
		getFilterOptions: build.query({
			query: () => ({
				url: `strollers/filteroptions`,
			}),
			providesTags: ['FilterOptions']
		}),
	}),
});

export const {
	useGetStrollersQuery,
	useGetLatestStrollersQuery,
	useGetStrollerQuery,
	useGetStrollerAccessoriesQuery,
	useGetFilterOptionsQuery,
} = strollersApi;