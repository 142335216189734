import React, { Fragment, useState } from 'react';

import { Icon, Modal } from 'UI';

const ProductDescription = ({ stroller }) => {
	const [open, setOpen] = useState(false);

	return (
		<Fragment>
			<Modal
				title="Produktbeskrivning"
				onClose={() => setOpen(false)}
				open={open}
			>
				{stroller != null ?
					<div
						dangerouslySetInnerHTML={{ __html: stroller.detailedText }}
					/>
				: <div />}
			</Modal>

			<div onClick={() => setOpen(true)} className="flex items-center justify-between py-6 border-b border-gray-300 font-semibold cursor-pointer">
				<div className="text-xl">
					Produktbeskrivning
				</div>

				<Icon name="Chevron" className="-rotate-90" />
			</div>
		</Fragment>
	);
}

ProductDescription.position = 'after_description';

export default ProductDescription;