import { Fragment } from 'react';
import { useGetLatestStrollersQuery } from 'services/strollers';
import { useGetBrandsQuery } from 'services/brands';
import { usePage } from 'hooks/page';

// Import modules
import Hero from './_modules/Hero';
import Brands from './_modules/Brands';
import Strollers from './_modules/Strollers';
import MakeChange from './_modules/MakeChange';
import Stars from './_modules/Stars';

const Home = () => {
	const { data: brands } = useGetBrandsQuery();
	const { data: strollers } = useGetLatestStrollersQuery();
	const [registerPosition] = usePage('Home', {
		brands: brands,
		strollers: strollers,
	}, [
		Hero,
		Brands,
		Strollers,
		MakeChange,
		Stars,
	]);

	return <Fragment>
		{registerPosition('hero')}

		<div className="mt-28"></div>

		{registerPosition('brands')}

		<div className="mt-10" />

		{registerPosition('strollers')}

		<div className="mt-20" />

		{registerPosition('content')}
	</Fragment>
}

export default Home;