import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getApiUrl, prepareHeaders } from 'lib/env';

export const userApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: getApiUrl() + 'api/v1/',
		prepareHeaders: prepareHeaders,
	}),
	reducerPath: 'user',
	tagTypes: ['User', 'Subscriptions'],
	endpoints: (build) => ({
		getUserPublicInformation: build.mutation({
			query: (personalNumber) => ({
				url: `users/${personalNumber}/information`,
				method: 'GET',
			}),
		}),
		getUserLoginStatus: build.query({
			query: (orderRef) => ({
				url: getApiUrl() + `api/v2.0/users/auth/status/${orderRef}`,
			})
		}),
		createLoginSession: build.query({
			query: (body) => ({
				url: getApiUrl() + 'api/v2.0/users/login?type=bankid',
				method: 'POST',
				body
			})
		}),
		getMe: build.query({
			query: () => ({
				url: `users/me`,
			}),
			providesTags: ['User'],
		}),
		submitContactForm: build.mutation({
			query: (values) => ({
				url: `users/contact`,
				method: 'POST',
				body: values,
			}),
		}),
	}),
});

export const {
	useGetUserPublicInformationMutation,
	useGetUserAuthStatusQuery,
	useGetMeQuery,
	useSubmitContactFormMutation,
} = userApi;