import { useIsFilterActive, useToggleFilter } from 'hooks/filters';
import { Expandable, Tag } from 'UI';

const ListBrandFilter = ({ filterOptions, deviceType, pageName }) => {
	const isActive = useIsFilterActive(pageName);
	const toggleFilter = useToggleFilter(pageName);

	return (
		<Expandable trigger="Märke" open={deviceType == 'unknown'}>
			<div className="flex flex-wrap pt-6">
				{filterOptions != null ?
					filterOptions.brands.map((brand) => {
						return (
							<Tag
								key={brand.brandId}
								active={isActive('brandIds', brand.brandId)}
								children={brand.name}
								onClick={(e) => {
									e.preventDefault();
									toggleFilter('brandIds', brand.brandId);
								}}
							/>
						);
					})
				: null}
			</div>
		</Expandable>
	);

};

ListBrandFilter.position = 'filters';

export default ListBrandFilter;
