import ContactForm from "containers/Forms/ContactForm";
import { useSubmitContactFormMutation } from "services/user";
import { usePage } from "hooks/page";

const Contact = () => {
	const [registerPosition, SEO] = usePage('contact');
	const [submitContactValues] = useSubmitContactFormMutation();

	const onContactSubmit = (values) => new Promise((resolve) => {
		submitContactValues(values).then(() => {
			resolve(1);
		});
	});

	return (
		<div className="mt-spacing">
			<SEO>
				<title>Kontakta oss</title>
			</SEO>

			<div className="mb-14 bg-moonlight flex flex-wrap">
				<div className="py-20 px-16 w-full md:w-7/12 order-last md:order-first">
					<h2 className="text-3xl font-semibold">
						Kontakta oss
					</h2>

					<p className="mt-2">
						Har du några funderingar? Eller önskemål?
					</p>
				</div>

				<div
					className="w-full md:w-5/12 bg-top bg-cover pt-[65%] md:pt-0"
					style={{ backgroundImage: "url('/assets/gang.jpg')"}}
				/>
			</div>

			<div className="flex flex-wrap gap-12">
				<div>
					<h3 className="text-2xl font-semibold mb-2 underline">Dataskydd</h3>
					<a className="text-blue" href="mailto:dataskydd@strollysweden.com">dataskydd@strollysweden.com</a>
				</div>

				<div>
					<h3 className="text-2xl font-semibold mb-2 underline">Info</h3>
					<a className="text-blue" href="mailto:info@strollysweden.com">info@strollysweden.com</a>
				</div>
			</div>

			<div className="mt-12">
				<h3 className="text-2xl font-semibold mb-2 underline">Kontaktformulär</h3>
				<ContactForm onSubmit={onContactSubmit} />
			</div>
		</div>
	);

};

export default Contact;