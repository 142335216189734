import { usePage } from 'hooks/page';

const Press = () => {
	const [registerPosition, SEO] = usePage('press');

	return (
		<div className="mt-spacing">
			<SEO>
				<title>Press</title>
			</SEO>

			<h1 className="text-4xl font-semibold">Press</h1>

			<article className="mt-16 md:flex items-center">
				<img
					className="h-80 md:h-96"
					src="/assets/rsz_strolly.jpg"
					alt="Grundarna Ebba Zetterblom och Nethusan Chandrakumar på inspelningen av Draknästet."
				/>

				<div className="md:ml-14">
					<div className="text-gray-700 text-sm font-semibold mt-6 md:mt-0 mb-4">
						2022-10-12
					</div>

					<h3 className="text-2xl font-semibold underline decoration-2">
						Strolly medverkar i Draknästet!
					</h3>

					<p className="mt-4 md:w-3/4">
						I april i år fick vi på Strolly den stora äran att medverka i inspelningen av Draknästet.
						Vi är otroligt stolta och glada över att vi fick möjligheten att pitcha vårt älskade Strolly inför drakarna!
						Nu återstår att se om vi fick med oss någon/några av drakarna Jacob De Geer, Sara Wimmercranz, Lena Apler, Jonas Eriksson eller Shervin Razani!
						Den 13e Oktober klockan 20.00 ser ni oss i tv-rutan när SVT sänder sista avsnittet av årets säsong.
					</p>
				</div>
			</article>

		</div>
	);

};

export default Press;
