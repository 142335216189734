import { Fragment } from 'react';
import { Expandable } from 'UI';

const InsuranceAnswers = () => {
	
	return (
		<Fragment>
			<Expandable trigger="Vilka försäkringar täcker min vagn?">
				Om du har en tecknad hemförsäkring täcker denna dina produkter du hyr från oss. 
				Om du önskar mer information om hur just din försäkring täcker eventuella skador och stölder ber vi dig därför ta kontakta med ditt försäkringsbolag.
			</Expandable>

			<Expandable trigger="Vad händer om jag har sönder eller skadar min vagn?">
				Du som kund är ansvarig för produkterna under hela hyresperioden. Om du har sönder eller skadar vagnen är du även ansvarig för att återställa detta. De flesta hemförsäkringar täcker dessa tillfällen och därför är det viktigt att du har en sådan.
				<br />
				Om du på något sätt skulle skada eller ha sönder en av dina produkter ber vi dig att kontakta oss på info@strollysweden.com. Tänk på att även skicka med ditt kundnummer och bilder på skadan. 
			</Expandable>

			<Expandable trigger="Vad händer om min vagn blir stulen?">
				Du som kund är ansvarig för produkterna under hela hyresperioden. Om vagnen blir stulen är du därför ansvarig för detta. De flesta hemförsäkringar täcker en sådan stöld och därför är det viktigt att du har en sådan. Du kan kontakta ditt försäkringsbolag om du har frågor kring det.
				<br />
				Om du har tappat bort din vagn, eller om den skulle bli stulen, ber vi dig kontakta oss på info@strollysweden.com. Ange ditt kundnummer tillsammans med ärendet i mailet. 
			</Expandable>

			<Expandable trigger="Det är något fel på produkterna jag fått">
				Om du skulle upptäcka eventuella fel och brister på dina produkter ber vi dig att kontakta oss på info@strollysweden.com. Skriv med ditt kundnummer och skicka med några bilder på den defekta produkten.
			</Expandable>
		</Fragment>
	);

};

InsuranceAnswers.position = 'insurance';

export default InsuranceAnswers;