import { Fragment } from 'react';
import { Expandable } from 'UI';

const ReturnAnswers = () => {
	
	return (
		<Fragment>
			<Expandable trigger="Är det gratis att returnera eller byta en vagn?">
				Innan bindningstiden är slut har du inte möjlighet att byta din vagn. 
				Efter bindningstidens slut är du däremot fri att välja en ny vagn i vårt sortiment om du önskar. 
				Efter bindningstidens slut kostar frakten för retur eller byte ingenting för dig som kund. 
				Läs mer om hur en retur fungerar här nedan.
			</Expandable>

			<Expandable trigger="I vilket skick ska vagnen returneras?">
				När vagnen ska skickas tillbaka till oss bortser vi från normalslittage, så som repor, enstaka fläckar etcetera. 
				Vad som inte är i godkänt skick är större skador och funktionsfel på produkten. 
				Exempel på sådana är stora revor i tyget, mögel och delar som fattas.
			</Expandable>

			<Expandable trigger="Vad händer när bindningstiden tar slut?">
				Du har 3 alternativ:<br /><br />

				<div className="pl-5">
					1. Avsluta din stream och skicka tillbaka vagnen till oss.
					Hör av dig till info@strollysweden.com för retursedel och ny kartong om du inte har sparat den som vagnen kom i.
					<br /><br />
					2. Fortsätt streama vagnen så länge du behöver den. Uppsägningstiden är då 1 månad. Du behöver inte göra något speciellt för detta alternativ. Så länge du inte säger upp avtalet räknar vi med att du vill ha kvar vagnen. 
					<br /><br />
					3. Byt till en annan vagn i vår vagnpark. Hör av dig till oss på info@strollysweden.com så hjälper vi dig med bytet.
				</div>
			</Expandable>

			<Expandable trigger="Kan jag returnera vagnen direkt när den kommer?">
				Om du inte är nöjd med vagnen när den kommer har du 14 dagar på dig att skicka tillbaka din vagn till oss. Däremot får du som kund stå för fraktkostnaden vid ett sådant tillfälle.
			</Expandable>

			<Expandable trigger="Har ni tagit emot min retur?">
				När vagnen har tagits emot och kontrollerats kommer du få ett mail.
			</Expandable>
		</Fragment>
	);

};

ReturnAnswers.position = 'return';

export default ReturnAnswers;