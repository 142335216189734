import { Fragment } from 'react';
import { Expandable } from 'UI';

const PricingAnswers = () => {
	
	return (
		<Fragment>
			<Expandable trigger="Hur prissätter vi vagnarna?">
				Vagnarna prissätts efter många olika aspekter. 
				Den främsta bedömningen sker efter vilken kategori (antal stjärnor i stjärnmodellen) vagnen är i. Efter det bedöms även märke, ålder, skick etcetera..
			</Expandable>

			<Expandable trigger="Hur bedömer vi antalet stjärnor per vagn?">
				Antalet stjärnor bedöms efter vilket skick vagnen är i.
				<br /><br />
				En 3-stjärnig vagn är helt ny eller i nyskick.<br />
				En 2-stjärnig vagn har varit uthyrd tidigare men är i väldigt gott skick.<br />
				En 1-stjärnig vagn fungerar felfritt och är i använt, men gott, skick.<br />
			</Expandable>

			<Expandable trigger="Hur håller Strolly tidigare uthyrda vagnar fräscha?">
				För oss är det väldigt viktigt att hålla den absolut bästa kvalitén, och därför rekondas varje vagn som kommer in till oss mycket noggrant. 
				Eventuella skador på delar av vagnen byts ut och alla tyger tvättas eller byts ut helt om det behövs. 
				Varje liten del på vagnen tas isär för att kunna göras rent på bästa sätt.
			</Expandable>
		</Fragment>
	);

};

PricingAnswers.position = 'pricing';

export default PricingAnswers;