import { Fragment } from 'react';
import { Expandable } from 'UI';

const CustomerAnswers = () => {

	return (
		<Fragment>
			<Expandable trigger="Jag vill bli kund hos er">
				När du har valt vilka produkter du önskar att beställa hem fyller du i dina personuppgifter och godkänner villkoren tillsammans med mobilt-bankID. Då blir du automatiskt kund hos oss.
			</Expandable>

			<Expandable trigger="Vad krävs för att hyra en vagn?">
				Om du önskar att bli kund hos oss kommer en kreditbedömning att tas. För att bli godkänd krävs det att du inte har några betalningsanmärkningar.
			</Expandable>

			<Expandable trigger="Vad är det för avtalstid?">
				Bindningstiden är antingen 6, 12 eller 24 månader, och den väljer du själv när du tecknar din nya vagn. Om du sedan väljer att byta vagn efter bindningstidens slut får du välja den på nytt för din nya vagn.
			</Expandable>

			<Expandable trigger="Jag har inte mobilt-bankID">
				Även om du inte har tillgång till mobilt-bankID för att signera villkoren har du fortfarande möjlighet att hyra våra produkter. Hör av dig till info@strollysweden.com så kommer vi att skicka ett manuellt avtal till dig.
			</Expandable>
		</Fragment>
	);

};

CustomerAnswers.position = 'customer';

export default CustomerAnswers;
