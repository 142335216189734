import { useState } from 'react';
import { Gallery, SmallGallery, Icon, Modal } from 'UI';

const ProductGallery = ({ stroller }) => {
	const [expand, setExpand] = useState(false);

	return (
		<div className="relative">
			<div className="absolute z-10 top-4 left-4">
				{[...new Array(3)].map((arr, index) => {
					return index < stroller.stars ?
						<Icon name="Star" key={index} className="!h-5" />
						: <Icon name="Star_Outline" key={index} className="!h-5" />
				})}
			</div>

			<SmallGallery images={stroller.images} />

			<div className="absolute z-10 right-4 top-4 hidden md:block">
				<Icon
					name="Expand"
					className="!h-5 cursor-pointer"
					onClick={() => setExpand(true)}
				/>
			</div>

			<Modal
				open={expand}
				onClose={() => setExpand(false)}
				type="fullscreen"
			>
				<div className="max-h-full h-full">
					<Gallery images={stroller.images} />
				</div>
			</Modal>
		</div>
	);

};

ProductGallery.position = 'before_description';

export default ProductGallery;