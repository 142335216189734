import React from 'react';
import { createRoot } from 'react-dom/client';
import { store, persistor } from 'lib/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import Trackers from 'components/Trackers';
import Commerce from 'containers/Commerce';
import ProtectedRoute from 'components/ProtectedRoute';

/* Import pages */
import Logout from 'containers/Pages/Logout';
import Checkout from 'containers/Pages/Checkout';

import './index.css';

const root = createRoot(document.getElementById('root'));
const Customer = React.lazy(() => import('./containers/Customer'));
const ThankYou = React.lazy(() => import('./containers/Pages/ThankYou'));

const App = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<Switch>
					{/* Redirects */}
					<Redirect from="/products" exact to="/barnvagnar" />
					<Redirect from="/products/:slug" to="/barnvagnar/:slug" />
					<Redirect from="/contact" to="/kontakta-oss" />
					<Redirect from="/terms" to="/villkor" />

					{/* Routes */}
					<Route path="/mina-sidor" component={() => (
						<React.Suspense fallback={() => null}>
							<ProtectedRoute>
								<Customer />
							</ProtectedRoute>
						</React.Suspense>
					)} />

					<Route path="/kassan" component={Checkout} />

					<Route path="/tack" component={() => (
						<React.Suspense fallback={() => null}>
							<ThankYou />
						</React.Suspense>
					)} />

					<Route path="/logout" component={Logout} />
					<Route path="/" component={Commerce} />
				</Switch>

				<Trackers />
			</BrowserRouter>
		</PersistGate>
	</Provider>
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
