import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPublicURL } from 'lib/url';
import { setToken } from 'services/authentication';

import BankIdSignForm from 'containers/Forms/BankIdSign';
import { Button, Icon, Modal } from 'UI';
import CartNavItem from './CartNavItem';
import Menu from 'components/Menu';

const Header = () => {
	const token = useSelector(state => state.authentication.token);
	const [mobile, setMobile] = useState(false);
	const [login, setLogin] = useState(false);
	const history = useHistory();

	const dispatch = useDispatch();

	const onLoginClick = () => {
		if (token != null) {
			history.push('/mina-sidor');
			return true;
		}

		setMobile(false);
		setLogin(true);
	};

	return (
		<div className="bg-white border-b border-gray-200">
			<div className="c--container-responsive flex justify-between items-center">
				<div>
					<Link className="block py-6 px-6 bg-green" to="/">
						<img className="h-9" src={`${getPublicURL()}assets/logo-transparent.png`} alt="Strolly logotype" />
					</Link>
				</div>

				<div className="hidden lg:flex flex-row items-center gap-10 text-gray-800">
					<div className="hover:font-medium">
						<Link to="/barnvagnar">
							Barnvagnar
						</Link>
					</div>

					<div className="hover:font-medium">
						<Link to="/faq">
							Vanliga frågor
						</Link>
					</div>

					<div className="hover:font-medium">
						<Link to="/kontakta-oss">
							Kontakta oss
						</Link>
					</div>

					<Link to="#" onClick={onLoginClick}>
						<Button variant="green-outline" className="relative pl-14">
							<Icon name="Bankid" className="!h-10 absolute left-3" />
							Mitt strolly
						</Button>
					</Link>

					<CartNavItem />
				</div>

				<div className="flex lg:hidden items-center gap-10 text-gray-800 pr-10 sm:pr-0">
					<div className="cursor-pointer" onClick={() => setMobile(true)}>
						<Icon name="Burger" className="!h-6" />
					</div>

					<CartNavItem />
				</div>
			</div>

			<Modal open={mobile} onClose={() => setMobile(false)}>
				<Menu
					onNavigate={() => setMobile(false)}
					onLogin={onLoginClick}
				/>
			</Modal>

			<Modal
				open={login}
				type="small"
				onClose={() => setLogin(false)}
				title={(
					<>
						<Icon name="Bankid" className="!h-12" /> Logga in
					</>
				)}
			>
				<BankIdSignForm
					onComplete={(response) => {
						// Login success
						dispatch(setToken(response.jwt));
						history.push('/mina-sidor');
					}}
				/>
			</Modal>
		</div>
	);
};

export default Header;