import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	items: [],
	widgetOpen: false,
	internalCount: 0,
	appliedCoupon: null,
	discount: 0,
};

export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		addCartItem: (state, action) => {
			state.internalCount++;
			state.items.push({
				cartItemId: state.internalCount,
				...action.payload,
			});
			state.widgetOpen = true;
		},
		removeCartItem: (state, action) => {
			state.items = state.items.filter((item) =>
				item.cartItemId != action.payload.cartItemId
			);
		},
		toggleWidget: (state) => {
			state.widgetOpen = !state.widgetOpen;
		},
		clearCart: (state) => {
			state.items = initialState.items;
		},
		setCartDiscount: (state, action) => {
			state.discount = action.payload.percentageReduce;
			state.appliedCoupon = action.payload.campaignCode;
		},
		clearCartDiscount: (state) => {
			state.discount = initialState.discount;
			state.appliedCoupon = initialState.appliedCoupon;
		},
	},
});

export const {
	addCartItem,
	removeCartItem,
	toggleWidget,
	clearCart,
	setCartDiscount,
	clearCartDiscount,
} = cartSlice.actions;

export default cartSlice.reducer;