import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import './_modal.css';
import Icon from './Icon';

// Reset all style applied by React Modal
ReactModal.defaultStyles.content = {};
ReactModal.defaultStyles.overlay = {};

// Set root element for accessibility
ReactModal.setAppElement('#root');

const Modal = (props) => {

	return (
		<ReactModal
			isOpen={props.open}
			onRequestClose={props.onClose}
			onAfterOpen={props.onOpen}
			// Animation timing
			closeTimeoutMS={300}
			className={`type-${props.type}`}
		>
			<div className="flex py-7 px-10 border-b border-gray-300 items-center justify-between">
				{props.title != null ?
					<div className="text-2xl flex items-center">
						{props.title}
					</div>
				: <div />}

				{props.onClose != null ?
					<div onClick={props.onClose} className="modal-close flex items-center h-full">
						<Icon name="Close" className="!h-8" />
					</div>
				: null}
			</div>

			<div className="max-h-full overflow-auto flex-1 modal-inner-content">
				{props.children}
			</div>
		</ReactModal>
	);

}

Modal.defaultProps = {
	open: false,
	title: null,
	onOpen: () => {},
	type: 'right',
};

Modal.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element,
	]),
	onClose: PropTypes.func,
	onOpen: PropTypes.func,
	type: PropTypes.oneOf([
		'fullscreen',
		'right',
		'small',
	]),
};

export default Modal;