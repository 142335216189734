import React from 'react';

const ProductTitle = ({ stroller, price }) => <>
	<div className="hidden md:block">
		<h1 className="text-4xl">{stroller.name}</h1>

		{price != null ?
			<div className="text-gray-900 mt-2">
				Från <span className="font-semibold">{price.price}:- / mån</span>
			</div>
		: null}

		<div className="my-8 border-b border-gray-300" />
	</div>
</>;

ProductTitle.position = 'before_form';

export default ProductTitle;