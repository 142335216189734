import { Link } from 'react-router-dom';
import { Button, Icon } from 'UI';

const MoreProducts = () => {

	return (
		<div className="mt-28 py-8 bg-gray-100">
			<h3 className="text-2xl text-center font-semibold">
				Inte vad du letar efter?
			</h3>

			<div className="flex justify-center flex-col md:flex-row items-center mt-4">
				<p className="mr-4 mb-4 md:mb-0 px-8 text-center">
					Utforska hela vårat utbud och hitta barnvagnen för dig!
				</p>

				<Link to="/barnvagnar">
					<Button variant="green-outline">
						Visa alla modeller
						<Icon name="Chevron" className="-rotate-90 ml-2" />
					</Button>
				</Link>
			</div>
		</div>
	);

};

MoreProducts.position = 'after_list';

export default MoreProducts;