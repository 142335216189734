import { Fragment } from 'react';
import { Expandable } from 'UI';

const OrderAnswers = () => {

	return (
		<Fragment>
			<Expandable trigger="Jag har inte fått någon orderbekräftelse">
				Din orderbekräftelse skickas automatiskt till dig via e-mail inom 24 timmar. Det finns en möjlighet att mailet kan hamna i din skräppost. Om du inte har fått din orderbekräftelse inom 24 timmar alls kan du kontakta oss på info@strollysweden.com så hjälper vi dig. Du kan även se din orderhistorik under Mina Sidor.
			</Expandable>

			<Expandable trigger="Jag har fått en skadad eller felaktig produkt">
				Om du har fått en skadad eller felaktig produkt kontaktar du oss på info@strollysweden.com så kommer vi att hjälpa dig. Skriv med ditt kundnummer, namnet på produkten och en bild på produkten i mailet. Ditt kundnummer finner du under Mina sidor eller på din orderbekräftelse.
			</Expandable>

			<Expandable trigger="En produkt saknas i min order">
				Om du saknar en produkt i din order ber vi dig att kontakta oss på info@strollysweden.com. Skriv med ditt kundnummer, namnet på produkten och en bild på produkten. Ditt kundnummer finner du under Mina sidor eller på din orderbekräftelse.
				Om ditt paket däremot blivit skadat eller har öppnats när du tar emot det ber vi dig att kontakta ditt postombud eller leveransservice direkt. Vi uppskattar också om du kan skicka en bild på det skadade paketet till oss. 
			</Expandable>

			<Expandable trigger="Finns det olika betalningsalternativ?">
				All betalning sker via Resurs Bank. Kontakta dem för mer information.
			</Expandable>

			<Expandable trigger="Vilka betalningsvillkor gäller?">
				Betalningsvillkoren från Resurs Bank hittas i kassan. För mer information kontakta Resurs Bank.
			</Expandable>

			<Expandable trigger="Kan jag ändra min order?">
				Du har endast möjlighet att ändra i ordern om varan inte hunnit lämna lagret. Kontakta oss på info@strollysweden.com så ska vi undersöka den möjligheten. Om varan redan hunnit skickats kan du välja att returnera varan när den kommer fram, men då får du som kund stå för fraktkostnaden tillbaka.
			</Expandable>
		</Fragment>
	);

};

OrderAnswers.position = 'orders';

export default OrderAnswers;
