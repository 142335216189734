import { usePage } from 'hooks/page';

import CustomerAnswers from './_modules/CustomerAnswers';
import ProductAnswers from './_modules/ProductAnswers';
import OrderAnswers from './_modules/OrderAnswers';
import DeliveryAnswers from './_modules/DeliveryAnswers';
import PricingAnswers from './_modules/PricingAnswers';
import ReturnAnswers from './_modules/ReturnAnswers';
import MyPagesAnswers from './_modules/MyPagesAnswers';
import InsuranceAnswers from './_modules/InsuranceAnswers';

const Faq = () => {
	const [ registerPosition, SEO ] = usePage('faq', null, [
		CustomerAnswers,
		ProductAnswers,
		OrderAnswers,
		DeliveryAnswers,
		PricingAnswers,
		ReturnAnswers,
		MyPagesAnswers,
		InsuranceAnswers,
	]);

	return (
		<div className="mt-spacing">
			<SEO>
				<title>Vanliga frågor</title>
			</SEO>

			<h1 className="text-4xl font-semibold">Vanliga frågor</h1>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Jag som kund
				</h3>

				{registerPosition('customer')}
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Produkter
				</h3>

				{registerPosition('products')}
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Order & Betalning
				</h3>

				{registerPosition('orders')}
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Leverans
				</h3>

				{registerPosition('delivery')}
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Prissättning / Stjärnmodellen
				</h3>

				{registerPosition('pricing')}
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Byte & Retur
				</h3>

				{registerPosition('return')}
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Mina sidor
				</h3>

				{registerPosition('mypages')}
			</section>

			<section className="mt-16">
				<h3 className="text-2xl font-semibold underline decoration-2">
					Försäkringar & Skada/Stöld
				</h3>

				{registerPosition('insurance')}
			</section>
		</div>
	);

};

export default Faq;