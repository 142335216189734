
/**
 * @param {object} params
 * @returns {string}
 */
export const buildParams = (params = {}) => {
	let url = '_=0';

	Object.keys(params).forEach((key) => {
		url += `&${key}=${params[key]}`;
	});

	return url;
};

/**
 * @param {string} str
 * @returns {string} Slug
 */
export const slugify = (str) => {
	str = str.replace(/^\s+|\s+$/g, ''); // trim
	str = str.toLowerCase();

	const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
	const to = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";
	// remove accents, swap ñ for n, etc
	for (var i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	}

	str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
		.replace(/\s+/g, '-') // collapse whitespace and replace by -
		.replace(/-+/g, '-'); // collapse dashes

	return str;
};

export const getIdFromSlug = (slug) => {
	const split = slug.split("-");
	return parseInt(split[split.length - 1], 10);
};

/**
 * @param {string} name 
 * @param {string} url
 * @returns Parameter value
 */
export const getParameterByName = (name, url) => {
	if (!url) url = window.location.href;
	name = name.replace(/[\[\]]/g, '\\$&');
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';

	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * Returns a prefix for public static resources URLs. Appends /
 * @returns {string} URL Prefix
 */
export const getPublicURL = () => {
	if (window.location.hostname === 'localhost' && process.env === 'development') {
		return '/';
	}

	return window.location.origin + '/';
};