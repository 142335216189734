import { useEffect } from "react";
import { useSelector } from "react-redux";
import { appInsights } from "lib/trackers";
import TagManager from 'react-gtm-module'

const Trackers = () => {
	const cookiesAccepted = useSelector((state) => state.cookies.accepted);
	const tagManagerArgs = {
		gtmId: 'GTM-WZ8F2Z7',
	};

	useEffect(() => {
		if (!cookiesAccepted) {
			return () => null;
		}

		appInsights.loadAppInsights();
		TagManager.initialize(tagManagerArgs);
	}, [cookiesAccepted]);

	return null;

};

export default Trackers;
