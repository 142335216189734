import { required } from 'lib/validation';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Button, Input, Modal, Textarea } from 'UI';

const ContactForm = (props) => {
	const [success, setSuccess] = useState(false);

	const onSubmit = (values, form) => new Promise((resolve) => {
		if (props.onSubmit != null) {
			props.onSubmit(values).then(() => {
				resolve(1);
				form.restart();
				setSuccess(true);
			});
		}
	});

	return (
		<div>
			<Modal open={success} type="small" title="Meddelande skickat!" onClose={() => setSuccess(false)}>
				<div className="p-10">
					Tack för ditt meddelande. Vi återkommer så snart vi kan.

					<Button variant="black" className="mt-4" onClick={() => setSuccess(false)}>
						Okej
					</Button>
				</div>
			</Modal>

			<Form
				onSubmit={onSubmit}
				render={({ submitting, valid, handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<div>
								<Field
									component={Input}
									name="email"
									label="Mejladress"
									validate={required}
								/>
							</div>

							<div className="mt-2">
								<Field
									component={Input}
									name="name"
									label="Namn"
									validate={required}
								/>
							</div>

							<div className="mt-2">
								<Field
									component={Textarea}
									name="message"
									label="Meddelande"
									validate={required}
								/>
							</div>

							<div className="mt-2">
								<Button disabled={!valid || submitting}>
									Skicka
								</Button>
							</div>
						</form>
					);
				}}
			/>
		</div>
	);

};

export default ContactForm;