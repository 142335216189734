import { Expandable, Icon, Tag } from 'UI';
import { useIsFilterActive, useToggleFilter } from 'hooks/filters';

const ListStarFilter = ({ filterOptions, deviceType, pageName }) => {
	const isActive = useIsFilterActive(pageName);
	const toggleFilter = useToggleFilter(pageName);

	return (
		<Expandable trigger="Stjärnor" open={deviceType == 'unknown'}>
			<div className="flex flex-wrap pt-6 text-green">
				{filterOptions != null ?
					filterOptions.stars.map((starCount, index) => {
						return (
							<Tag
								key={index}
								active={isActive('stars', starCount)}
								onClick={() => toggleFilter('stars', starCount)}
							>
								{[...new Array(3)].map((arr, index) => {
									return index < starCount ?
										<Icon name="Star" key={`star-${index}`} /> :
										<Icon name="Star_Outline" key={`star-${index}`} />
								})}
							</Tag>
						);
					})
					: null}
			</div>
		</Expandable>
	);

};

ListStarFilter.position = 'filters';

export default ListStarFilter;