import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from 'UI';

const Expandable = ({ open: initialValue, trigger, children }) => {
	const [open, setOpen] = useState(initialValue);
	const [animating, setAnimating] = useState(false);
	const containerEl = useRef(null);
	const innerEl = useRef(null);

	const TRANSITION_DURATION = 300;

	const getElements = () => {
		/**
		 * @type {HTMLDivElement} container
		 * @type {HTMLDivElement} inner
		 */
		const container = containerEl.current;
		const inner = innerEl.current;
		return { container, inner };
	};

	useEffect(() => {
		if (open) {
			const { container } = getElements();
			container.style.height = '';
		}
	}, []);

	const onTriggerClick = () => {
		if (animating) {
			return false;
		}

		const { container, inner } = getElements();

		setAnimating(true);
		setOpen(!open);

		container.style.height = inner.scrollHeight + 'px';

		if (!open) {
			setTimeout(() => {
				setAnimating(false);
				container.style.height = '';
			}, TRANSITION_DURATION);
		} else {
			setTimeout(() => {
				container.style.height = 0 + 'px';
			}, 10);

			setTimeout(() => {
				setAnimating(false);
			}, TRANSITION_DURATION);
		}
	};

	return (
		<div>
			<div
				className={cx(
					"cursor-pointer",
					"flex",
					"items-center",
					"justify-between",
					"py-6",
					"border-b",
					"border-gray-300",
					"font-semibold",
				)}
				onClick={onTriggerClick}
			>
				{trigger}

				<Icon
					name="Chevron"
					className={cx(
						'ease-in-out',
						'transition-all',
						{
							"rotate-180": open,
						}
					)}
				/>
			</div>

			<div
				className={cx(
					'overflow-hidden',
					{
						'duration-300': animating,
						'ease-in-out': animating,
						'transition-all': animating,
					}
				)}
				style={{ height: 0 }}
				ref={containerEl}
			>
				<div ref={innerEl}>
					{children}
				</div>
			</div>
		</div>
	);

};

Expandable.defaultProps = {
	open: false,
	trigger: null,
};

Expandable.propTypes = {
	open: PropTypes.bool,
	trigger: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element,
	]),
};

export default Expandable;
