import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { requiredSilent } from 'lib/validation';
import { clearCartDiscount } from 'services/cart';

import { Button, Icon, Input } from 'UI';

const CampaignCodeForm = (props) => {
	const dispatch = useDispatch();
	const discount = useSelector((state) => state.cart.discount);
	const appliedCoupon = useSelector((state) => state.cart.appliedCoupon);

	const onClear = () => {
		dispatch(clearCartDiscount());
	};

	const onSubmit = (values) => {
		return new Promise((resolve) => {
			if (props.onSubmit != null && typeof props.onSubmit === 'function') {
				props.onSubmit(values).then(() => {
					resolve(1);
				});

				return true;
			}

			resolve(1);
		});
	};

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={{
				code: appliedCoupon,
			}}
			render={({ handleSubmit, submitting, valid }) => {
				return (
					<form onSubmit={handleSubmit}>
						<label className="block mb-1">
							Har du en kampanjkod?
						</label>

						<div className="flex flex-col md:flex-row items-start">
							<div className="w-full">
								<Field
									component={Input}
									name="code"
									validate={requiredSilent}
								/>
							</div>

							<div className="mt-2 md:mt-0 md:ml-4">
								<Button
									variant='black'
									disabled={submitting || !valid}
								>
									Lägg till
								</Button>
							</div>
						</div>

						{appliedCoupon != null ?
							<div className="mt-2 text-sm text-green">
								<Icon name="Checkmark" className="!h-3 mr-1" />
								Kupongen <strong>{appliedCoupon} ({discount}%)</strong> applicerad.

								<span className="ml-2 font-semibold cursor-pointer" onClick={onClear}>
									Ta bort
								</span>
							</div>
						: null}

					</form>
				);
			}}
		/>
	);

};

export default CampaignCodeForm;