import { Fragment } from 'react';
import { Expandable } from 'UI';

const DeliveryAnswers = () => {
	
	return (
		<Fragment>
			<Expandable trigger="Hur lång är leveranstiden?">
				Om allt går som det ska har vi en leveranstid på cirka 3-5 arbetsdagar.
			</Expandable>

			<Expandable trigger="Kan jag spåra min order?">
				Ja det kan du. När din vagn har skickats från vårt lager får du ett separat mail med uppgifter om hur du kan spåra ditt paket.
			</Expandable>
		</Fragment>
	);

};

DeliveryAnswers.position = 'delivery';

export default DeliveryAnswers;