import { Switch, Route } from 'react-router-dom';
import { useOnRouteChange } from 'hooks/routes';

import Header from 'components/Header';
import Footer from 'components/Footer';
import CookieNotice from 'components/Cookies/Notice';

/* Import pages */
import Home from 'containers/Pages/Home';
import ListStrollers from 'containers/Pages/Strollers/List';
import SingleStroller from 'containers/Pages/Strollers/Single';
import SingleBrand from 'containers/Pages/Brands/Single';
import Faq from 'containers/Pages/Faq';
import Terms from 'containers/Pages/Terms';
import PrivacyPolicy from 'containers/Pages/PrivacyPolicy';
import Contact from 'containers/Pages/Contact';
import Press from 'containers/Pages/Press';

const Commerce = () => {
	useOnRouteChange();

	return (
		<div className="flex flex-col justify-between h-full">
			<div>
				<Header />

				<div className="c--container">
					<Switch>
						<Route path="/" exact component={Home} />
						<Route path="/barnvagnar" exact component={ListStrollers} />
						<Route path="/barnvagnar/:slug" component={SingleStroller} />
						<Route path="/varumarken/:slug" component={SingleBrand} />
						{/* <Route path="/about" exact component={About} /> */}
						<Route path="/faq" exact component={Faq} />
						<Route path="/villkor" exact component={Terms} />
						<Route path="/integritetspolicy" exact component={PrivacyPolicy} />
						<Route path="/kontakta-oss" exact component={Contact} />
						<Route path="/press" exact component={Press} />
					</Switch>
				</div>
			</div>

			<Footer />
			<CookieNotice />
		</div>
	);

};

export default Commerce;