import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	accepted: false,
};

export const cookiesSlice = createSlice({
	name: 'cookies',
	initialState,
	reducers: {
		acceptCookies: (state) => {
			state.accepted = true;
		},
	},
});

export const {
	acceptCookies,
} = cookiesSlice.actions;

export default cookiesSlice.reducer;