import React from 'react';
import { Link } from 'react-router-dom';
import StrollerCard from 'components/StrollerCard';

import { Button, Icon, Swiper } from 'UI';

const Strollers = ({ strollers }) => <>
	<div className="flex flex-col md:flex-row mb-8 md:items-center md:justify-between">
		<div className="text-3xl pr-3">
			Våra senaste modeller
		</div>

		<div className="mt-3 md:mt-0">
			<Link to="barnvagnar">
				<Button variant="green-outline">
					Visa alla modeller
					<Icon name="Chevron" className="-rotate-90 ml-2" />
				</Button>
			</Link>
		</div>
	</div>

	<Swiper
		slidesPerView={1}
		spaceBetween={24}
		breakpoints={{
			520: {
				slidesPerView: 2,
				spaceBetween: 24
			},
			845: {
				slidesPerView: 3,
				spaceBetween: 24
			},
		}}
		style={{ paddingBottom: 26 }}
	>
		{strollers != null ?
			strollers.map((stroller) => {
				return (
					<StrollerCard
						key={stroller.strollerId}
						stroller={stroller}
					/>
				);
			})
		: null}
	</Swiper>
</>

Strollers.position = 'strollers';
export default Strollers;