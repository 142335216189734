import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
	const token = useSelector(state => state.authentication.token);

	if (token == null) {
		return <Redirect to='/login' />
	}

	return children;
};

export default ProtectedRoute;