import React from 'react';

const Grid = ({ children }) => {
	return (
		<div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-14 md:gap-y-20`}>
			{children}
		</div>
	);

}

export default Grid;