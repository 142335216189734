import React from 'react';
import { getPriceHTML } from 'lib/numbers';
import cx from 'classnames';

const AccessoriesSelect = ({ accessories, input }) => {

	const getValue = () => {
		if (input.value == null || input.value == '') {
			return [];
		}

		return input.value;
	};

	const onAccessoryClick = (accessory) => {
		let currentValue = [...getValue()];

		const foundInValue = currentValue.findIndex((item) =>
			item.productId == accessory.products[0].productId
		);

		if (foundInValue == -1) {
			currentValue.push({
				...accessory.products[0],
				name: accessory.name,
				referenceId: accessory.accessoryId,
				reference: 'ACCESSORY',
			});
		} else {
			currentValue.splice(foundInValue, 1);
		}

		input.onChange(currentValue);
	};

	return (
		<div>
			{accessories.map((accessory) => {
				if (accessory.products == null || accessory.products.length == 0) {
					return null;
				}

				const selected = getValue().findIndex((item) =>
					item.productId == accessory.products[0].productId
				) != -1;

				return (
					<div
						className={cx(
							'relative',
							'overflow-hidden',
							'flex',
							'items-center',
							'flex-row',
							'bg-gray-100',
							'rounded',
							'transition',
							'ease-in-out',
							'cursor-pointer',
							'p-4',
							'mb-3',
							'border',
							'hover:border-green/50',
							{
								'border-gray-200': !selected,
								'border-green': selected,
							}
						)}
						onClick={() => onAccessoryClick(accessory)}
						key={accessory.accessoryId}
					>
						<div
							className="w-[6rem] h-[6rem] mix-blend-multiply bg-center bg-cover mr-6"
							style={{ backgroundImage: `url(${accessory.imageFiles[0]?.url})` }}
						/>

						<div className="flex justify-between lg:items-center flex-1 flex-col lg:flex-row">

							<div className="basis">
								<h3 className="text-sm font-medium mr-7">
									{accessory.name} {accessory.products[0].productId}
								</h3>
							</div>

							<div
								className={cx(
									'transition-all',
									'mt-2',
									'lg:mt-0',
									'shrink-0',
									{
										'pr-0': !selected,
										'pr-7': selected,
									}
								)}
							>
								{getPriceHTML(accessory.products[0])} / månad
							</div>

							{/* <div className="text-sm"
							dangerouslySetInnerHTML={{ __html: accessory.description }}
						/> */}

						</div>

						<div
							className={cx(
								'absolute',
								'top-0',
								'right-0',
								'h-full',
								'bg-green',
								'flex',
								'items-center',
								'justify-center',
								'transition-all',
								'ease-in-out',
								'opacity-0',
								{
									'w-0': !selected,
									'w-7': selected,
									'opacity-100': selected,
								}
							)}
						>
							<div className="-rotate-90 text-white text-sm font-semibold">
								VALD
							</div>
						</div>
					</div>
				)
			})}
		</div>
	);

}

export default AccessoriesSelect;