import { useState, useTransition } from 'react';

/**
 * @param {number} initialValue Initial value
 * @param {number} duration Animation duration in ms
 */
export const useAnimatedDigit = (initialValue = 0, duration = 250) => {
	const [value, setValue] = useState(initialValue);
	const [isPending, startTransition] = useTransition();

	const updateValue = (newValue) => {
		if (newValue == value) {
			return false;
		}

		startTransition(() => {
			let startTimestamp = null;
			const tick = (timestamp) => {
				if (!startTimestamp) startTimestamp = timestamp;
				const progress = Math.min((timestamp - startTimestamp) / duration, 1);

				setValue(Math.floor(progress * (newValue - value) + value));

				if (progress < 1) {
					window.requestAnimationFrame(tick);
				}
			};

			window.requestAnimationFrame(tick);
		});
	};

	return [
		value,
		updateValue,
		isPending,
	];
};