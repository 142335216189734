import React, { useId } from 'react';
import cx from 'classnames';

const Textarea = ({ label, ...props }) => {
	const id = useId();

	return (
		<>
			{label != null ?
				<label className="block mb-1" htmlFor={id}>
					{label}
				</label>
			: null}

			<textarea
				id={id}
				className={cx(
					'transition-colors',
					'duration-300',
					'border',
					'border-gray-700/40',
					'py-4 px-5',
					'rounded-md',
					'block',
					'w-full',
					'outline-none',
					'focus:border-gray-700',
				)}
				{...props.input}
				{...props}
			/>

			{props.meta?.modified &&
				((props.meta?.error && (
					<span style={{ marginTop: "1rem", color: "red" }}>
						{props.meta.error}
					</span>
				)) ||
					(props.meta?.warning && (
						<span style={{ marginTop: "1rem", color: "red" }}>
							{props.meta.warning}
						</span>
					)))}
		</>
	);

}

export default Textarea;
