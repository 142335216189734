import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<div className="mt-28 py-8 bg-gray-100 border-t border-gray-200">
			<div className="c--container text-sm">
				<div className="flex flex-row gap-x-6 gap-y-2 items-center justify-center text-center text-gray-900 flex-wrap">
					<div>
						<Link to="/kontakta-oss">
							Kontakta oss
						</Link>
					</div>

					<div>
						<Link to="/barnvagnar">
							Barnvagnar
						</Link>
					</div>

					<div>
						<Link to="/faq">
							Vanliga frågor
						</Link>
					</div>

					<div>
						<Link to="/villkor">
							Villkor
						</Link>
					</div>

					<div>
						<Link to="/integritetspolicy">
							Integritetspolicy
						</Link>
					</div>

					<div>
						<Link to="/press">
							Press
						</Link>
					</div>
				</div>

				<div className="flex flex-row gap-x-6 gap-y-2 items-center justify-center text-center text-gray-900 flex-wrap mt-4">
					<div>
						<a target="_blank" rel="noreferrer" href="https://www.instagram.com/strollyse/">
							Instagram
						</a>
					</div>

					<div>
						<a target="_blank" rel="noreferrer" href="https://www.facebook.com/strollyse">
							Facebook
						</a>
					</div>
				</div>

				<div className="mt-6">
					<div className="text-center text-gray-700">
						Copyright © 2022 Strolly AB
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;