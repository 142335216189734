import { useMemo } from 'react';

export const useGetLowestPrice = (product) => {
	return useMemo(() => {
		if (product == null || product.products == null) {
			return null;
		}

		const lowest = product.products.reduce((lowest, product) => {
			if (lowest == null || product.price < lowest.price) {
				return product;
			}

			return lowest;
		});

		return lowest;
	}, [product]);
};
