import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearFilters } from "services/filters";
import { checkTokenValidity } from "services/authentication";
import { appInsights } from "lib/trackers";

export const useOnRouteChange = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(clearFilters());
		dispatch(checkTokenValidity());

		appInsights.trackPageView({
			name: location.pathname,
		})
	}, [location.pathname]);

	return undefined;
};