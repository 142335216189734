import { Link } from 'react-router-dom';
import { Icon } from 'UI';

const FilterLink = () => {

	return (
		<div className="mt-4">
			<Link to="/barnvagnar" className="inline-flex items-center font-semibold text-blue">
				Visa alla modeller
				<Icon name="Chevron" className="-rotate-90 ml-2" />
			</Link>
		</div>
	);

};

FilterLink.position = 'filters';

export default FilterLink;