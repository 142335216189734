import cx from 'classnames';
import { getPublicURL } from 'lib/url';

const MakeChange = () => {

	return (
		<div className="flex flex-wrap items-center py-10">
			<div className="w-full md:w-1/2 md:pr-10">
				<div
					className={cx(
						'pt-[85%]',
						'relative',
						'flex',
						'items-center',
						'justify-center',
					)}
				>
					<div
						className={cx(
							'w-full',
							'h-full',
							'absolute',
							'top-0',
							'bg-no-repeat',
							'bg-cover',
							'bg-center',
						)}
						style={{ backgroundImage: `url('${getPublicURL()}assets/mom_baby.jpg')` }}
						alt=""
					/>
				</div>
			</div>

			<div className="w-full md:w-1/2 md:pl-10 mt-10 md:mt-0">
				<h3 className="text-2xl">Var med och förändra!</h3>

				<p className="text-gray-700 text-md mt-2">
					Det ska vara enkelt att välja hållbart! Börja streama en barnvagn idag och spara upp till 64% co2 jämfört med att köpa en ny. Bra för plånboken, bra för planeten.
				</p>

				<div className="flex justify-between mt-8">
					<div className="w-1/2 mr-2">
						<strong className="block font-bold mb-1">Hur fungerar det?</strong>
						
						<p className="text-gray-700 text-sm">
							Välj den vagn och de tillbehör du önskar från vår barnvagnspark. 
							Om vagnen streamats tidigare rekondas den innan du får den.
						</p>
					</div>

					<div className="w-1/2 ml-2">
						<strong className="block font-bold mb-1">Vad händer sen?</strong>

						<p className="text-gray-700 text-sm">
							När du inte längre har behov av vagnen avslutar du enkelt ditt abonnemang och skickar tillbaka vagnen till oss.<br />
						</p>
					</div>
				</div>

				{/* <div className="mt-6">
					<Link to="/about" className="inline-flex items-center font-semibold text-blue uppercase text-sm">
						Läs mer om oss
						<Icon className="ml-1 -rotate-90 !h-3" name="Chevron" />
					</Link>
				</div> */}

				{/* <div className="mt-4 font-bold">
					Du sparar pengar, tid och och plats hemma - bra för dig, bra för planeten! 
				</div> */}
			</div>
		</div>
	);

};

MakeChange.position = 'content';

export default MakeChange;