import React, { useState } from 'react';
import { Icon, Modal } from 'UI';

const ProductSpecifications = ({ stroller }) => {
	const [open, setOpen] = useState(false);

	return <>
		<Modal
			title="Specifikationer"
			onClose={() => setOpen(false)}
			open={open}
		>
			{stroller != null ?
				<table className="table-auto w-full">
					<tbody>
						{stroller.specifications != null ?
							stroller.specifications.map((specification, index) => {
								return (
									<tr className="odd:bg-gray-200" key={index}>
										<td className="p-3">
											{specification.key}
										</td>

										<td className="p-3 text-right font-semibold">
											{specification.value}
										</td>
									</tr>
								);
							})
						: null}
					</tbody>
				</table>
			: null}
		</Modal>

		<div onClick={() => setOpen(true)} className="flex items-center justify-between py-6 border-b border-gray-300 font-semibold cursor-pointer">
			<div className="text-xl">
				Specifikationer
			</div>

			<Icon name="Chevron" className="-rotate-90" />
		</div>
	</>
}

ProductSpecifications.position = 'after_description';

export default ProductSpecifications;