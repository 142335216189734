import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Icon } from 'UI';

const CartItem = () => {
	const items = useSelector((state) => state.cart.items);

	return (
		<Link to="/kassan" className="relative">
			<Icon name="Stroller" className="!h-6" />

			<div
				style={{ top: '-.75rem', right: '-1rem' }}
				className={cx(
					'absolute',
					'rounded-full',
					'bg-green',
					'w-5',
					'h-5',
					'justify-center',
					'items-center',
					'flex',
					'text-white',
					'text-xs',
					'transition-all',
					'duration-200',
					'delay-200',
					{
						'opacity-0': items.length == 0,
						'scale-75': items.length == 0,

						'opacity-100': items.length > 0,
						'scale-100': items.length > 0,
					}
				)}
			>
				{items.length}
			</div>
		</Link>
	);
}

export default CartItem;