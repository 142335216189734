import { Link } from "react-router-dom";
import { Icon } from 'UI';

const ListItem = ({ children, ...rest }) => {

	return (
		<Link
			className="flex items-center justify-between py-6 border-b border-gray-300 font-semibold cursor-pointer"
			{...rest}
		>
			<div className="text-xl">
				{children}
			</div>

			<Icon name="Chevron" className="-rotate-90" />
		</Link>
	);

};

export default ListItem;