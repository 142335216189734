import app from '../../package.json';

export const getEnvironment = () => {
	if (window.location.hostname == 'strollysweden.com') {
		return app.environments.production;
	}

	return app.environments.development;
};

export const getApiUrl = () => {
	const environment = getEnvironment();
	return environment.api_url;
};

export const prepareHeaders = (headers, { getState }) => {
	if (getState().authentication.token != null) {
		headers.set('authorization', `Bearer ${getState().authentication.token}`);
	}

	return headers;
};
