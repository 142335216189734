import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetAuthenticationState } from "services/authentication";

const Logout = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		dispatch(resetAuthenticationState());
		history.push('/');
	}, []);

	return null;

};

export default Logout;