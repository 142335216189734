import React from 'react';
import cx from 'classnames';
import { getPriceHTML } from 'lib/numbers';

const StrollerSelect = ({ input, stroller }) => {

	const getValue = () => {
		if (input.value == null || input.value == '') {
			return [];
		}

		return input.value;
	};

	const onProductClick = (product) => {
		let currentValue = [...getValue()];

		currentValue = currentValue.filter((item) =>
			item.reference != 'STROLLER'
		);

		currentValue.push({
			...product,
			deliveries: stroller.deliveries,
			paymentMethod: stroller.paymentMethod,
			referenceId: stroller.strollerId,
			reference: 'STROLLER',
		});

		input.onChange(currentValue);
	};

	return (
		<div className="grid xl:grid-cols-3 gap-4 mt-4">
			{stroller.products.map((product) => {
				const selected = getValue().find((_product) =>
					_product.reference == 'STROLLER' && _product.productId == product.productId
				) != null;

				return (
					<div
						className={cx(
							'duration-300',
							'relative',
							'py-5',
							'md:py-8',
							'flex',
							'justify-center',
							'items-center',
							'flex-col',
							'bg-gray-100',
							'rounded',
							'transition-all',
							'ease-in-out',
							'cursor-pointer',
							'border',
							'border-transparent',
							'hover:border-green/50',
							{
								'bg-gray-100': !selected,
								'bg-green': selected,
								'text-white': selected,
							}
						)}
						key={product.productId}
						onClick={() => onProductClick(product)}
					>
						<h2 className="text-2xl">
							{product.subscriptionPeriod} månader
						</h2>

						<div>
							{getPriceHTML(product)} / månad
						</div>
					</div>
				);
			})}
		</div>
	);

}

export default StrollerSelect;